/*
  Pricing Table 
------------------------------*/
.pricing-table__faqs {
    padding-top: 60px;
    padding-bottom: 40px;
    @include tablet {
        padding-top: 75px;
        padding-bottom: 60px;
    }
    @include desktops {
        padding-top: 113px;
        padding-bottom: 100px;
    }

    .section-title {
        margin-bottom: 58px;
        &__heading {
            font-size: 32px;
            font-weight: 500;
            letter-spacing: normal;
            line-height: 1.38095;
            @include tablet {
                font-size: 35px;
            }
            @include desktops {
                font-size: 42px;
            }
        }
    }

    .faq-body {
        @include extra-large-desktops {
            margin: 0 65px;
        }
    }
}
.faqs__single {
    margin-bottom: 40px;
    @include desktops {
        margin-bottom: 60px;
    }

    @include large-desktops {
        margin-right: 50px;
    }

    @include extra-large-desktops {
        margin-right: 150px;
    }

    .faqs__icon {
        min-width: 37px;
        max-width: 37px;
        min-height: 37px;
        max-height: 37px;
        background-color: #ff5722;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
    }
    .faqs__content {
        &__title {
            font-size: 21px;
            font-weight: 500;
            color: var(--color-headings-2);
            letter-spacing: normal;
            line-height: 30px;
        }
        &__text {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 30px;
            color: $boulder;
        }
    }
}

/*
  Fag Inner Page
------------------------------*/
.faqs-area--inner {
    padding-top: 50px;
    padding-bottom: 40px;
    @include tablet {
        padding-top: 70px;
        padding-bottom: 60px;
    }
    @include desktops {
        padding-top: 105px;
        padding-bottom: 120px;
    }
    .section-title {
        margin-bottom: 61px;
    }
}
.faq-tabs {
    border: 1px solid rgba(181, 181, 181, 0.2);
    background-color: $white;
    margin-right: 8px;
    padding-top: 20px;
    padding-bottom: 25px;
    .nav-link {
        color: rgba($shark, 0.7);
        font-size: 18px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 25px;
        padding: 14px 25px;

        &.active {
            color: $egg-blue;
            background-color: transparent;
        }
    }
}

.tab-content {
    .accordion {
        &-item {
            border: none;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
            background-color: $white;
            margin-bottom: 20px;
        }
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &-button {
            border: none;
            padding: 0;
            margin-left: 32px;
            margin-right: 35px;
            font-size: 21px;
            font-weight: 500;
            letter-spacing: normal;
            line-height: 30px;
            padding-top: 47px;
            padding-bottom: 24px;
            color: $shark;
            position: relative;
            transition: 0.4s;
            &:focus,
            &:not(.collapsed) {
                outline: none;
                box-shadow: none;
                background-color: transparent;
            }
            &::after {
                content: "";
                color: transparent;
                background-image: none;
            }
            @include mobile-lg {
                &::after {
                    content: "\f068";
                    font-family: "Font Awesome 5 Free";
                    position: absolute;
                    right: 0;
                    font-size: 16px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-weight: 700;
                    background-image: none;
                    color: $egg-blue;
                }
            }

            &.collapsed {
                padding-top: 45px;
                padding-bottom: 45px;
                &::after {
                    content: "\f067";
                    font-family: "Font Awesome 5 Free";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-weight: 700;
                    background-image: none;
                }
            }
        }
        &-collapse {
            border: none;
        }
        &-body {
            padding-bottom: 43px;
            padding-top: 0;
            border: none;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 32px;
            margin-left: 40px;
            color: rgba($shark, 0.7);
            position: relative;
            @include tablet {
                margin-right: 70px;
                font-size: 18px;
            }
            &:before {
                content: "";
                position: absolute;
                left: -6px;
                top: 0;
                width: 4px;
                height: 93px;
                background-color: $egg-blue;
            }
        }
    }
}

.buttons {
    p,
    a {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 32px;
    }
    p {
        color: var(--color-headings-2);
    }
}
