
@mixin dark-theme-mode{
  --bg:                 #19191b;
  --bg-2:                 #19191b;
  --bg-3:                 #19191b;
  --bg-4:                 #19191b;
  --bg-5:                 #19191b;
  --bg-6:                 #19191b;
  --bg-7:                 #19191b;
  --bg-8:                 #19191b;
  --bg-9:                 #19191b;
  --color-headings:       #fff;
  --color-headings-2:     #fff;
  --color-headings-3:     #fff;
  --color-texts:          rgba(255,255,255, 0.7);
  --color-texts-2:        rgba(255,255,255, 1);
  --color-texts-3:        rgba(255,255,255, 0.7);
  --border-color:         rgba(255,255,255, 0.08);
  --border-color-2:       rgba(255,255,255, 0.08);
  --border-color-3:       rgba(255,255,255, 0.08);
  --border-color-4:       rgba(255,255,255, 0.08);
  --border-color-5:       rgba(255,255,255, 0.08);
  --border-color-6:       rgba(255,255,255, 0.08);
}
@mixin light-theme-mode{
  --bg:                    #fff;
  --bg-2:                  #f8f9fc;
  --bg-3:                  #f3f4f6;
  --bg-4:                  rgba(169, 210, 255, 0.1);
  --bg-5:                  #f2f5fb;
  --bg-6:                  #f9fafc;
  --bg-7:                  #fdfdfd;
  --bg-8:                  #e6f8f9;
  --bg-9:                  rgba(230, 248, 249, 0.4);
  --color-headings:      #25373f;
  --color-headings-2:      #262729;
  --color-headings-3:      #000;
  --color-texts:        rgba(37, 55, 63, 0.7);
  --color-texts-2:       #3b4656;
  --color-texts-3:      rgba(38, 39, 41, 0.7);
  --border-color:       rgba(128, 138, 142, 0.2);
  --border-color-2:       rgba(37, 55, 63, 0.1);
  --border-color-3:      rgba(38, 39, 41, 0.1);
  --border-color-4:      rgba(181, 181, 181, 0.2);
  --border-color-5:     rgba(38, 39, 41, 0.3);
  --border-color-6:     rgba(130, 130, 136, 0.3);
}


// #FCFDFE, #F4F7FA, #F8F8F8, #ECF2F7, #FDFDFF, 


@mixin dark-mode {
  [data-theme='dark'] & ,.dark-mode-texts & {
      @content;
  }
}
@mixin light-mode {
  [data-theme='dark'] & ,.dark-mode-texts & {
      @content;
  }
}
