.shadow {
    &--primary {
        box-shadow: -12px 12px 50px rgba(253, 52, 110, 0.3);
    }

    &--primary-2 {
        box-shadow: 4px 4px 56px rgba(255, 87, 34, 0.3);
    }

    &--primary-3 {
        box-shadow: 4px 4px 65px rgba(255, 87, 34, 0.5);
    }

    &--primary-4 {
        box-shadow: 0 4px 4px rgba(253, 100, 52, 0.3);
    }
    &--primary-5 {
        box-shadow: 0 10px 20px rgba(255, 87, 34, 0.25);
    }
    &--primary-6 {
        box-shadow: -12px 12px 50px rgba(255, 87, 34, 0.3);
    }
    &--primary-7 {
        box-shadow: 0px 10px 20px rgba(255, 87, 34, 0.25);
    }

    &--electric-violet-2 {
        box-shadow: -12px 12px 50px rgba(96, 1, 211, 0.3);
    }

    &--electric-violet-2-2 {
        box-shadow: 4px 4px 56px rgba(80, 52, 252, 0.3);
    }

    &--electric-violet-2-3 {
        box-shadow: 0 4px 4px rgba(96, 1, 211, 0.3);
    }
    &--electric-violet-2-4 {
        box-shadow: -12px 20px 50px rgba(80, 52, 252, 0.3);
    }

    &--electric-violet-3 {
        box-shadow: 4px 4px 50px rgba(80, 52, 252, 0.45);
    }
    &--electric-violet-4 {
        box-shadow: 0 10px 20px rgba(96, 1, 211, 0.25);
    }

    &--electric-violet-3-2 {
        box-shadow: -12px 12px 50px rgba(96, 1, 211, 0.3);
    }

    &--flamingo {
        box-shadow: 4px 4px 50px rgba(239, 67, 57, 0.45);
    }

    &--egg-blue {
        box-shadow: 0 4px 45px rgba(10, 191, 188, 0.45);
    }
    &--egg-blue-2 {
        box-shadow: -12px 12px 50px rgba(10, 191, 188, 0.3);
    }

    &--blue-ribbon-2 {
        box-shadow: 0 20px 20px rgba(0, 65, 255, 0.2);
    }

    &--orange {
        box-shadow: 0 20px 20px #ffe8cd;
    }

    &--torch-red {
        box-shadow: 0 20px 20px rgba(255, 30, 56, 0.2);
    }

    &--torch-red-2 {
        box-shadow: -12px 12px 50px rgba(255, 30, 56, 0.3);
    }
    &--torch-red-3 {
        box-shadow: 0 10px 20px rgba(255, 30, 56, 0.25);
    }
    &--torch-red-4 {
        box-shadow: -12px 20px 50px rgba(255, 30, 56, 0.3);
    }

    &--readical-red {
        box-shadow: 0 4px 44px rgba(249, 58, 112, 0.4);
    }
    &--readical-red-2 {
        box-shadow: 0 4px 4px rgba(253, 52, 110, 0.3);
    }
    &--readical-red-3 {
        box-shadow: 0 10px 20px rgba(253, 52, 110, 0.25);
    }
    &--readical-red-4 {
        box-shadow: 0 20px 20px rgba(253, 52, 110, 0.3);
    }

    &--java-2 {
        box-shadow: 0 20px 20px #d1fffb;
    }

    &--yellow {
        box-shadow: 0 20px 20px rgba(252, 220, 0, 0.2);
    }

    &--icon-readical-red {
        box-shadow: 0 20px 20px #fedbe5;
    }

    &--icon-yellow {
        box-shadow: 0 20px 20px #fff9d3;
    }

    &--icon-primary {
        box-shadow: 0 20px 20px #ffd9cd;
    }

    &--icon-java-2 {
        box-shadow: 0 20px 20px #d1fffb;
    }

    &--bittersweet {
        box-shadow: 0 4px 4px rgba(253, 52, 110, 0.3);
    }
    &--bittersweet-2 {
        box-shadow: 0 10px 20px rgba(255, 114, 114, 0.6)
    }
    &--niagara {
        box-shadow: 0 4px 4px #82c4c3;
    }
    &--niagara-2 {
        box-shadow: 0 10px 20px rgba(9, 154, 151, 0.6);
    }
    &--niagara-3 {
        box-shadow: -8px 10px 50px rgba(9, 154, 151, 0.3);
    }
}

