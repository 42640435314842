.portfolio-area--l7,
.portfolio-area--inner-1,
.portfolio-area--inner-2,
.portfolio-area--related-post {
    padding-top: 55px;
    padding-bottom: 40px;

    @include tablet {
        padding-top: 75px;
        padding-bottom: 60px;
    }

    @include desktops {
        padding-top: 120px;
        margin-top: 0;
    }

    .section-title {
        margin-bottom: 10px;

        @include desktops {
            margin-bottom: 45px;
        }

        &__sub-heading {
            margin-bottom: 13px;
        }
    }

    a.portfolio__single {
        position: relative;
        margin-bottom: 25px;
        border-radius: 15px;
        display: inline-block;
        margin-bottom: 25px;

        .portfolio__hover {
            position: absolute;
            left: 50%;
            bottom: 18px;
            padding: 20px 15px;
            border-radius: 10px;
            width: 90%;
            transform: translateX(-50%);
            overflow: hidden;
            transition: all 0.7s ease-in-out;
            visibility: hidden;
            opacity: 0;

            @include mobile {
                padding: 20px 35px;
            }

            &__heading {
                font-size: 18px;
                font-weight: 500;
                letter-spacing: normal;
                line-height: 30px;
                margin-bottom: 0;

                @include mobile-lg {
                    font-size: 21px;
                }
            }

            &__text {
                font-size: 15px;
                font-weight: 400;
                letter-spacing: normal;
                line-height: 28px;
                margin-bottom: 0;
            }
        }

        &:hover {
            .portfolio__hover {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .btn-link {
        font-weight: 500;
        font-size: 21px;
        line-height: 30px;

        i {
            margin: 0 10px;
            font-size: 18px;
        }
    }
}

.portfolio-menu {
    button {
        padding-left: 20px;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 26px;
        color: var(--color-headings-2);
        transition: 0.4s;
        background-color: transparent;
        border: none;

        &:hover {
            color: $readical-red;
        }
    }
}

/*
  Portfolio Area Inner Page 1
---------------------------------*/

.portfolio-area--inner-1 {
    position: relative;

    @include desktops {
        padding-bottom: 120px;
    }

    .portfolio__single {
        margin-bottom: 25px;
    }

    .shape {
        position: absolute;
        right: 0;
        top: 0;
        width: 8%;
    }
}

.portfolio-details {
    padding-top: 45px;

    @include tablet {
        padding-top: 65px;
    }

    @include desktops {
        padding-top: 85px;
    }
}

.portfolio-content-box {
    &__heading {
        font-size: 32px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 54px;
        text-transform: uppercase;
        color: var(--color-headings-2);
        margin-bottom: 25px;

        @include tablet {
            font-size: 35px;
        }

        @include desktops {
            font-size: 45px;
        }
    }

    &__text {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 32px;
        color: var(--color-texts-3);
        margin-bottom: 20px;
        margin-right: 10px;
    }

    &__list {
        ul {
            li {
                font-size: 18px;
                font-weight: 500;
                letter-spacing: normal;
                line-height: 25px;
                position: relative;
                padding-left: 30px;
                margin-bottom: 20px;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 11px;
                    height: 11px;
                    border-radius: 50%;
                    background-color: $primary;
                }

                span {
                    color: var(--color-headings-2);
                    margin-right: 15px;
                }
            }
        }
    }
}

/*
  Portfolio Area Inner Page 2
---------------------------------*/

.portfolio-area--inner-2 {
    padding-top: 15px;
    padding-bottom: 35px;

    @include tablet {
        padding-top: 50px;
        padding-bottom: 70px;
    }

    @include desktops {
        padding-top: 90px;
        padding-bottom: 130px;
    }
}
/*
  Portfolio Area Slider Details Page 2
---------------------------------*/

.portfolio-area--slider-details-2 {
    padding-top: 30px;
    padding-bottom: 80px;

    @include tablet {
        padding-top: 50px;
        padding-bottom: 70px;
    }

    @include desktops {
        padding-top: 90px;
        padding-bottom: 130px;
    }
}

/*
  Portfolio Area Inner Page 2
---------------------------------*/
.portfolio-details-area {
    padding-top: 60px;

    @include tablet {
        padding-top: 80px;
    }

    @include desktops {
        padding-top: 120px;
    }
}

/*
  Home 9 : Portfolio Area
---------------------------------*/
.portfolio-area--l9 {
    padding-top: 60px;
    padding-bottom: 60px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 115px;
        padding-bottom: 230px;
    }

    .section-title {
        margin-bottom: 35px !important;

        @include desktops {
            margin-bottom: 70px !important;
        }

        &__sub-heading {
            padding-left: 0 !important;

            &::before {
                display: none;
            }
        }
    }
}

.portfolio-img {
    border-radius: 15px;
    box-shadow: 0 4px 56px rgba(0, 0, 0, 0.1);

    &__single {
        position: relative;

        img {
            border-radius: 15px;
        }

        &__shape {
            position: absolute;
            left: 0;
            bottom: 0;

            .shape-content {
                img {
                    position: relative;
                }

                h1 {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    z-index: 1;
                    transform: translate(-50%, -50%);
                    font-size: 36px;
                    font-weight: 700;
                    letter-spacing: normal;
                    line-height: 54px;
                    color: $white;
                }
            }
        }
    }
}

.portfolio-content {
    &__sub-heading {
        color: var(--color-texts-3);
        margin-bottom: 28px;
        padding-left: 0 !important;
    }

    &__heading {
        font-size: 45px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 54px;
        text-transform: capitalize;
        color: var(--color-headings-2);
        margin-bottom: 13px;
    }

    &__heading-small {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 28px;
        color: var(--color-headings-2);
        margin-bottom: 25px;
        display: block;
    }

    &__text {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 32px;
        margin-bottom: 45px;
    }
}


.portfolio-details-slider {
    .slick-list {
        @include desktops {
            width: 150%;
        }
    }


    &__single {
        padding: 0 12px;
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

}

.portfolio-details-dots {
    width: 100%;
    height: 6px;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    position: relative;

    li {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        list-style: none;
        button {
            padding: 0;
            width: 0;
            margin: 0;
            border: none;
            background-color: transparent;
            font-size: 0;
            transition: width 3s linear;
            transition-delay: 1.3s;
        }

        &.slick-active {
            display: block;
            width: 100%;

            button {
                background: $electric-violet-2 !important;
                border-radius: 5px;
                height: 100%;
                width: 100%;
                transition: width 2s linear;
                transition-delay: 0.8s;
                opacity: 1;
                z-index: 1;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
}

.portfolio-details--3 {
    padding-top: 45px;
    padding-bottom: 0px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 0px;
    }

    @include desktops {
        padding-top: 115px;
        padding-bottom: 0px;
    }

    .portfolio-area--details-3 {
        padding-top: 60px !important;

        @include tablet {
            padding-top: 80px !important;
        }

        @include desktops {
            padding-top: 130px !important;
        }
    }

    .portfolio-details {
        @include large-desktops {
            padding-right: 120px;
        }

        .portfolio-content-box__text {
            margin-bottom: 40px;
        }
    }
}