.contact-section--l3 {
  padding-bottom: 60px;
  padding-top: 60px;

  @include media-breakpoint-up(sm) {
    padding-bottom: 80px;
    padding-top: 80px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 120px;
    padding-top: 120px;
  }

  .contact-widget-box {
    @include media-breakpoint-up(lg) {
      margin-left: 30px;
    }

    @include media-breakpoint-up(xl) {
      margin-left: 95px;
      margin-right: 0;
    }

    @include media-breakpoint-up(xxl) {
      margin-left: 100px;
      margin-right: 80px;
    }
  }

  .section-title {
    &__sub-heading {
      margin-bottom: 10px;
    }
  }
}

.contact-widget-box {
  padding: 30px 30px 15px;

  @include mobile-lg {
    padding: 40px 50px 15px;
  }

  background-color: $electric-violet-2;
  border-radius: 15px;

  .widget--contact {
    margin-bottom: 20px;
  }
}

.contact-widget-box__title-block {
  padding-bottom: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 30px;
  margin-bottom: 35px;

  .widget-box__title {
    color: #ffffff;
    font-family: "Rubik", sans-serif;
    font-size: 32px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 42px;
    text-align: left;
  }

  .widget-box__paragraph {
    color: #ffffff;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 28px;
    text-align: left;
    margin-bottom: 0;
  }
}

/*
  Home 4 : Footer
---------------------------------*/
.contact-section--l4,
.contact-section--inner-2 {
  padding-top: 60px;

  @include media-breakpoint-up(sm) {
    padding-bottom: 80px;
    padding-top: 80px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 120px;
    padding-top: 120px;
  }

  .contact-widget-box {
    @include media-breakpoint-up(lg) {
      margin-left: 30px;
    }

    @include media-breakpoint-up(xl) {
      margin-left: 95px;
      margin-right: 0;
    }

    @include media-breakpoint-up(xxl) {
      margin-left: 100px;
      margin-right: 80px;
    }
  }

  .section-title {
    margin-bottom: 45px;
    @include desktops {
      margin-bottom: 80px !important;
    }
    &__sub-heading {
      margin-bottom: 10px;
    }
  }
}

/*
  Contact -1
---------------------------------*/
.contact-section--l4 {
  .contact-form {
    .form-check-label {
      font-size: 15px;
      font-weight: 400;
      letter-spacing: normal;
      line-height: 28px;
    }
  }
}

/*
  Contact -1
---------------------------------*/
.contact-inner-1 {
  padding-top: 55px;
  padding-bottom: 60px;
  @include tablet {
    padding-top: 75px;
    padding-bottom: 80px;
  }
  @include desktops {
    padding-top: 130px;
    padding-bottom: 110px;
  }
  .contact-widget-box {
    padding: 40px 45px 15px;
  }
}
