/*
  Home 2 : Cta section
---------------------------------*/
.cta-section--l2 {
  padding-top: 60px;
  padding-bottom: 80px;
  @include tablet {
    padding-top: 80px;
  }
  @include desktops {
    padding-top: 135px;
    padding-bottom: 130px;
  }

  &__button {
    z-index: 9;
  }
}

.cta-section--l2__content {
  border-radius: 20px;
  padding: 40px 40px;
  overflow: hidden;
  box-shadow: 0 44px 34px rgba(37, 55, 63, 0.1);
  z-index: 0;
  position: relative;
  @include tablet {
    padding-top: 72px;
    padding-bottom: 80px;
    padding-left: 75px;
    padding-right: 95px;
  }

  @include desktops {
    padding-bottom: 75px;
  }

  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    background-image: url(../image/home-2/l2-cta-mask.png);
    background-position: right;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    display: none;
    z-index: -1;

    @include tablet {
      display: block;
      right: -25%;
      width: 110%;
    }

    @include desktops {
      right: -40%;
    }

    @include large-desktops {
      width: 50%;
      right: 0;
    }
  }
  &__heading {
    font-weight: 500;
    letter-spacing: -2px;
    line-height: 1.2083;
    font-size: 38px;
    margin-bottom: 40px;
    text-align: center;
    @include mobile {
      padding: 0 30px;
    }
    @include tablet {
      padding: 0;
    }

    @include desktops {
      font-size: 48px;
      margin-bottom: 0;
      text-align: left;
    }

    @include large-desktops {
      padding-right: 40px;
    }
  }
}

.cta-area--l6,
.cta-area--l8 {
  padding-top: 60px;
  padding-bottom: 60px;
  @include tablet {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @include desktops {
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .section-title {
    &__description {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: normal;
      line-height: 32px;
      color: var(--color-texts-3);
    }
  }
  .button-group {
    margin-top: 38px;

    .btn {
      margin: 0 8px;
      margin-bottom: 10px;
      font-size: 14px;
      min-width: 170px;
      height: 56px;
    }
  }
}

.cta-area--l8 {
  padding-top: 60px;
  padding-bottom: 30px;
  @include tablet {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @include desktops {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}
