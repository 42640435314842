.check-input-control {
  margin-bottom: 20px;

  input:checked {
    &~.checkbox {
      background: $primary !important;
      border-color: $primary;

      &::after {
        border-color: $primary;
        background-color: $primary;
      }
    }
  }

  .checkbox {
    position: relative;
    line-height: 1;
    min-width: 14px;
    max-width: 14px;
    min-height: 14px;
    max-height: 14px;
    border-radius: 2px;
    // background-color: #e7e9ed;
    border: 1px solid gray;
    display: inline-block;
    cursor: pointer;

    &:after {
      content: "";
      color: black;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 2px;
      border: 1px solid #e5e5e5;
      background-color: #ffffff;
    }
  }
}

/* Newsletter One */
.newsletter-form {
  &__text {
    margin-top: 15px;
    margin-bottom: 0;
    color: rgba(38, 39, 41, 0.7);
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 28px;
  }

  .btn {
    width: 100%;
    height: 70px;
    border-radius: 10px;

    @include mobile {
      width: 172px;
    }
  }

  input {
    width: 100%;
    border-radius: 10px;
    border: 2px solid rgba(128, 138, 142, 0.2);
    color: rgba(37, 55, 63, 0.7);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 28px;
    padding: 5px 25px;
    margin-bottom: 10px;
    height: 70px;

    @include mobile {
      margin-right: 7px;
      margin-bottom: 0;
    }
  }
}

/* Newsletter Two */

.newsletter--footer {
  max-width: 531px;
  margin-top: 45px;
  margin-bottom: 50px;

  @include desktops {
    margin-bottom: 155px;
  }

  input {
    transition: 0.4s;
    background-color: rgba($white, 0.2);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    width: 100%;
    border: none !important;
    padding: 0 25px;

    &::placeholder {
      color: rgba($white, 0.7);
    }

    &:focus {
      background-color: #fff !important;

      &::placeholder {
        color: #000;
      }
    }
  }
}

.newsletter-form--l2,
.newsletter-form--countdown {
  border-radius: 75px;
  max-width: 516px;

  @include mobile {
    background-color: $link-water;
    border: 2px solid $white-lilac;
  }

  input {
    margin-left: 5px;

    @include mobile {
      border: none;
      background-color: transparent;
    }

    &:focus {
      background-color: transparent !important;

      &::placeholder {
        color: #000;
      }
    }
  }

  .btn {
    height: 50px !important;

    @include mobile {
      margin-right: 10px;
    }
  }
}

/*
  Home 3 : Footer
---------------------------------*/
.contact-form,
.contact-form-inner-2 {
  .form-floating {
    label {
      padding-left: 25px;
      font-size: 15px;
      min-height: 45px;
      padding-top: 0;
      padding-bottom: 0;
      color: rgba($shark, 0.5);
    }

    textarea {
      min-height: 175px;

      &~label {
        margin-top: 20px;
      }
    }

    input {
      &~label {
        display: flex;
        align-items: center;
      }
    }
  }

  .form-control {
    background: #fff;
    padding-left: 25px;
    padding-right: 25px;
    border: 0;
    font-size: 15px;
  }

  input.form-control {
    border-radius: 500px;
    background: #fff;
    color: $shark;
    height: 45px !important;

    &::placeholder {
      color: transparent;
    }
  }

  textarea {
    min-height: 175px;
    border-radius: 15px;

    &~label {}
  }

  .form-check {
    input.form-check-input {
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      border-radius: 3px;
      border: 0;

      &:checked[type="checkbox"] {
        background-color: var(--bs-secondary) !important;
        box-shadow: none;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .form-check-label {
      margin-left: 8px;
      font-size: 13px;
    }
  }
}

/*
  Home 4 : Contact Form
---------------------------------*/
.contact-section {
  &__form-heading {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 30px;
    color: var(--color-headings-2);
    margin-bottom: 28px;
  }

  .form-control,
  .form-check-input {
    border: 1px solid var(--border-color-3) !important;
  }
}

.newsletter-form--rounded {
  position: relative;

  .form-control {
    height: 45px;
    border-radius: 50px;
    border: 1px solid rgba(38, 39, 41, 0.2);
    background-color: #fff;
    color: rgba(38, 39, 41, 0.8);

    &::placeholder {
      color: rgba(38, 39, 41, 0.5);
    }
  }

  .btn-icon {
    width: 30px;
    height: 30px;
    box-shadow: 0 10px 30px rgba(253, 52, 110, 0.3);
    background-color: #ff5722;
    color: #fff;
    border-radius: 500px;
    position: absolute;
    right: 10px;
    top: 40%;
    border: 0;
    transform: translateY(-50%);

    @include mobile {
      margin-bottom: 0;
      top: 50%;
      right: 16px;
    }
  }
}

/*
  Home 6 : Promo Secion
---------------------------------*/

.promo--l6-newsletter {
  border-radius: 56px;

  @include mobile {
    background-color: $watermelon;
  }

  input {
    background-color: $watermelon;
    border-radius: 56px;
    height: 60px;

    @include mobile {
      background-color: transparent;
    }

    border: none;
    color: $white !important;

    &:placeholder {
      color: $white !important;
      border-radius: 56px;
      background-color: $watermelon;
    }
  }

  .btn {
    min-width: 140px;
    height: 50px;
    border-radius: 25px;
    box-shadow: 0 8px 55px rgba(96, 1, 211, 0.4);

    @include mobile {
      margin-right: 4px;
    }
  }
}

.newsletter--l7 {
  form {
    text-align: right;

    input {
      width: 100%;
      border: none;
      height: 56px;
      border-radius: 50px;
      background-color: $white;
      margin-bottom: 20px;
      padding: 0 20px;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .btn {
      width: 150px;
      height: 56px;
      border-radius: 50px;
      color: $white;
      text-align: right;
      margin-right: auto;
    }
  }
}

/*
  Home 6 : Promo Secion
---------------------------------*/

.contact-section--inner-2 {
  .contact-form {

    input,
    textarea,
    .form-check-input {
      background-color: transparent !important;
    }
  }

  .form-check-label {
    font-size: 15px !important;
    font-weight: 400;
    line-height: 28px;
    color: var(--color-texts-3);
  }
}

/* Search Form */

.search-form {
  background-color: $white;
  border-radius: 9px;
  padding: 0 40px;

  form {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      height: 75px;
      border: none;
      width: 100%;
      border-radius: 9px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: normal;
      line-height: 30px;
      color: rgba($shark, 0.7);

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    button {
      border: none;
      background-color: transparent;
      color: $readical-red;
      font-size: 21px;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.newsletter-form--countdown {
  max-width: inherit;

  @include mobile {
    background-color: $white;
  }
}

.newsletter--inner-career {
  form {
    text-align: center;

    @include mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $white;
      text-align: left;
    }

    border-radius: 50px;
    padding: 0 10px;

    .form-control {
      background-color: $white !important;
      display: flex;
      align-items: center;
      border: none;
      height: 72px;
      border-radius: 50px;
      margin-bottom: 10px;

      @include mobile {
        background-color: transparent;
        margin-bottom: 0;
      }

      i {
        margin-right: 10px;
      }

      input {
        height: 100%;
        background-color: transparent;
        border: none;
        width: 100%;
        display: block;

        &:focus {
          border: none;
          outline: none;
        }
      }
    }
  }
}

/*
  Home 9 : Promo Sections
---------------------------------*/
.promo--l9-newsletter {
  form {
    @include mobile {
      background-color: rgba($white, 0.4);
    }

    border-radius: 35px;

    input {
      height: 58px;

      @include mobile {
        background-color: transparent;
      }

      &:focus,
        {
        background-color: transparent;
      }

      &::placeholder {
        @include mobile {
          color: rgba($white, 0.6);
        }
      }
    }

    .btn {
      min-width: 50px;
      max-width: 50px;
      min-height: 50px;
      max-height: 50px;
      border-radius: 50%;
    }
  }
}


.newsletter--l4 {
  
  form {
    text-align: center;

    @include mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $white;
      text-align: left;
    }

    border-radius: 50px;
    padding: 0 10px;

    .form-control {
      background-color: $white !important;
      display: flex;
      align-items: center;
      border: none;
      height: 72px;
      border-radius: 50px;
      margin-bottom: 10px;

      @include mobile {
        background-color: transparent;
        margin-bottom: 0;
      }

      i {
        margin-right: 10px;
        font-weight: 300;
         font-size: 18px;
      }

      input {
        height: 100%;
        background-color: transparent;
        border: none;
        width: 100%;
        display: block;

        &:focus {
          border: none;
          outline: none;
        }
      }

    }

    .btn {
      min-width: 174px;
      height: 56px;
      border-radius: 50px;
    }
  }

  &__text {
    color: $jumbo;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 30px;
    margin-top: 25px;
  }
}