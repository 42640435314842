.blog-details {
    padding-top: 50px;
    padding-bottom: 40px;
    @include tablet {
        padding-top: 70px;
        padding-bottom: 60px;
    }
    @include desktops {
        padding-top: 125px;
        padding-bottom: 110px;
    }
}
.blog-title {
    padding-bottom: 30px;
    @include desktops {
        padding-bottom: 55px;
    }
    &__heading {
        font-size: 25px;
        font-weight: 500;
        letter-spacing: -1px;
        line-height: 1.3;
        margin-bottom: 20px;
        @include mobile-lg {
            font-size: 32px;
        }
        @include tablet {
            font-size: 35px;
        }
        @include desktops {
            font-size: 48px;
        }
    }
}

.blog__metainfo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__author-name {
        font-size: 13px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 32px;
        color: var(--color-texts-3);
        padding-right: 15px;
        padding-left: 55px;
        @include tablet {
            padding-left: 70px;
            padding-right: 25px;
            font-size: 18px;
        }
        position: relative;
        &::before {
            content: "";
            position: absolute;
            width: 41px;
            height: 1px;
            left: 0;
            top: 50%;
            background-color: $primary;
        }
    }
    &__date {
        font-size: 13px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 32px;
        color: var(--color-texts-3);
        position: relative;
        @include tablet {
            padding-right: 25px;
            font-size: 18px;
        }

        padding-left: 30px;
        &::before {
            content: "";
            position: absolute;
            width: 1px;
            height: 21px;
            left: 0;
            top: 0;
            background-color: rgba($shark, 0.7);
        }
    }
}

.blog-content {
    &__box {
        padding-top: 5px;
    }
    &__img {
        margin-bottom: 35px;
        @include desktops {
            margin-bottom: 55px;
        }
    }
    &__heading {
        font-size: 21px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 30px;
        margin-bottom: 17px;
    }
    &__text {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 32px;
        margin-bottom: 30px;
    }

    &__list {
        padding: 0;
        margin: 0;
        margin-left: 20px;
        li {
            font-size: 18px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: 32px;
            margin-bottom: 8px;
        }
    }
}

.qoute {
    &__content {
        font-size: 21px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 34px;
        margin-left: 45px;
        position: relative;
        color: var(--color-headings-2);
        margin-bottom: 35px;
        &::before {
            content: "\f10d";
            font-family: "Font Awesome 5 Free";
            position: absolute;
            font-weight: 700;
            font-size: 25px;
            left: -45px;
            top: 0;
            color: $primary;
        }
    }
}

.post-tags-section {
    @include desktops {
        padding-top: 20px;
    }
    &__title {
        font-size: 21px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 30px;
        margin-bottom: 0;
        margin-right: 25px;
        margin-top: 10px;
    }
    .post-tags {
        li {
            display: inline-flex;
            align-items: center;

            a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-width: 75px;
                min-height: 50px;
                border-radius: 5px;
                border: 1px solid rgba(22, 22, 22, 0.12);
                background-color: $white;
                color: rgba($shark, 0.7);
                padding: 0 23px;
                margin-right: 10px;
                margin-top: 5px;
                margin-bottom: 5px;
                flex-wrap: wrap;
                transition: 0.4s;
                &:hover {
                    background-color: $electric-violet-2;
                    color: $white;
                }
            }
        }
    }
}

.post-social-share {
    padding-bottom: 20px;
    @include desktops {
        padding-bottom: 0;
        padding-top: 10px;
    }
    &__title {
        font-size: 21px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 30px;
        margin-bottom: 0;
        margin-right: 25px;
    }
    .social-share {
        margin-top: 8px;
        li {
            display: inline-block;
            a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: var(--color-texts-3);
                padding: 0 15px;
                flex-wrap: wrap;
                transition: 0.4s;
                font-size: 21px;
                &:hover {
                    color: $electric-violet-2;
                }
            }
        }
    }
}
.next-prev-btn {
    @include desktops {
        padding-top: 85px;
    }
    .btn-link {
        font-size: 21px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 30px;
        display: flex;
        align-items: center;
        color: var(--color-headings-2);
        transition: 0.4s;
        &:hover {
            color: $electric-violet-2;
        }
        &--prev {
            i {
                margin-right: 10px;
            }
        }
        &--next {
            i {
                margin-left: 10px;
            }
        }
    }
}

.blogs-area--right-sidebar,
.blogs-area--left-sidebar,
.blog-area--reguler {
    padding-top: 60px;
    padding-bottom: 60px;
    @include tablet {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @include desktops {
        padding-top: 110px;
        padding-bottom: 120px;
    }

    .section-title {
        padding-bottom: 30px;
        @include desktops {
            padding-bottom: 50px;
        }
    }
}

.blogs-post {
    position: relative;
    margin-bottom: 24px;
    border-radius: 10px;
    overflow: hidden;
    &--big {
        border-radius: 10px;
        min-height: 190px;
    }
    &--small {
        width: 100%;
    }
    .hover-content {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-radius: 10px;
        background-image: linear-gradient(
            360deg,
            rgba(0, 0, 0, 0.9) 0%,
            rgba(0, 0, 0, 0.67) 45%,
            rgba(0, 0, 0, 0) 100%
        );
        padding-left: 20px;
        padding-bottom: 20px;
        @include mobile-lg {
            padding-left: 30px;
        }
        @include desktops {
            padding-left: 40px;
        }
        &__badge {
            font-size: 11px;
            font-weight: 500;
            letter-spacing: normal;
            line-height: 20px;
            min-width: 78px;
            height: 26px;
            border-radius: 15px;
            margin-right: 12px;
            &:hover {
                color: $white;
            }
        }
        &__date {
            margin-bottom: 0;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 22px;
            color: $white;
            transition: 0.4s;
            &:hover {
                color: $primary;
            }
        }
        &__title {
            font-size: 18px;
            font-weight: 500;
            letter-spacing: normal;
            line-height: 25px;
            color: $white;
            margin-bottom: 19px;
            display: block;
            transition: 0.4s;
            &:hover {
                color: $primary;
            }
        }
        &__post-meta {
            li {
                a {
                    color: $white;
                    font-size: 15px;
                    font-weight: 500;
                    letter-spacing: normal;
                    line-height: 26px;
                    padding-right: 30px;
                    position: relative;
                    transition: 0.4s;
                    &:hover {
                        color: $primary;
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        width: 1px;
                        height: 14px;
                        background-color: $white;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:last-child {
                        &::before {
                            content: "";
                            position: absolute;
                            width: 1px;
                            height: 14px;
                            background-color: transparent;
                            right: 15px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                    i {
                        font-weight: 500;
                        margin-right: 10px;
                        color: $primary;
                    }
                }
            }
        }
    }
    .hover-content__top {
        margin-bottom: 20px;
    }
}

.pagination {
    ul {
        li {
            display: inline-flex;
            align-items: center;
            a {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 36px;
                height: 36px;
                border: 1px solid rgba(38, 39, 41, 0.1);
                background-color: $white;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: normal;
                line-height: 25px;
                color: $shark;
                transition: 0.4s;
                margin: 0 5px;
                &:hover {
                    background-color: $electric-violet-2;
                    color: $white;
                }
            }
        }
    }
}

/*
  Home 9
---------------------------------*/
.blog-area--l9 {
    padding-top: 60px;
    padding-bottom: 25px;
    @include tablet{
      padding-top: 60px;
      padding-bottom: 45px;
    }
    @include desktops{
      padding-top: 115px;
      padding-bottom: 225px;
    }
    .section-title {
        margin-bottom: 30px;
        @include desktops{
            margin-bottom: 63px;
        }
        &__sub-heading {
            padding-left: 0 !important;
            &::before {
                display: none;
            }
        }
    }
    .blogs-post {
        .hover-content {
            transition: 0.4s;
            padding-top: 23px;
            padding-bottom: 10px;
            padding-left: 25px;
            padding-right: 30px;

            &__badge {
                &:hover {
                    color: $white;
                }
            }

            &__post-meta {
                li {
                    a {
                        font-size: 13px;
                        font-weight: 400;
                        letter-spacing: normal;
                        line-height: 22px;
                    }
                }
            }
        }

        // &:hover {
        //     .hover-content {
        //         background-image: none;
        //         background-color: rgba($electric-violet-2, 1);
        //     }
        // }
    }
}
