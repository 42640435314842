.widget {
  display: flex;

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    &--circle {
      border-radius: 500px;
    }
  }
}

/*
  Home 1 : widget -- content-section
-------------------------------------*/
.widget--contact {
  display: flex;

  .widget-icon {
    min-width: 55px;
    min-height: 55px;
    max-width: 55px;
    max-height: 55px;
    margin-right: 30px;
    background-color: rgba($shark, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    color: $white;
  }

  .widget-body {
    &--title {
      color: $white;
      font-size: 15px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 26px;
      text-align: left;
      margin-bottom: 5px;
    }

    p {
      opacity: 0.7;
      color: $white;
      font-size: 13px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      margin-bottom: 10px;
    }
  }

  &.active {
    .widget-icon {
      color: $secondary;
      background-color: $white;
    }
  }
}

/*
  Home 1 : widget -- Testimonial Section
------------------------------------------*/

.widget--testimonial {
  flex-direction: column;

  &__description {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 34px;
    margin-bottom: 50px;
    color: var(--color-headings);
    margin-right: 50px;

    @include desktops {
      margin-right: 5px;
    }

    @include large-desktops {
      font-size: 21px;
    }
  }
}

.widget--profile {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  &__image {
    margin-right: 15px;
    min-width: 67px;
    max-width: 67px;
    min-height: 67px;
    max-height: 67px;
    border-radius: 50%;
  }

  &__name {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.619;
    margin-bottom: 0;
  }

  &__position {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.75;
  }
}

/*
  Home 1 : widget -- Counter Section
------------------------------------------*/
.widget--counter {
  margin-bottom: 30px;
  flex-direction: column;

  @include desktops {
    margin-bottom: 0;
  }

  &__title {
    font-size: 35px;
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 1.13461;
    margin-bottom: 25px;

    @include tablet {
      font-size: 42px;
    }

    @include desktops {
      font-size: 52px;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.5;

    @include tablet {
      font-size: 21px;
    }

    @include desktops {
      font-size: 24px;
    }
  }
}

/*
  Home 2 : widget -- Feature Section
------------------------------------------*/

.widget--feature-2 {
  flex-direction: column;

  @include mobile {
    flex-direction: row;
    align-items: center;
  }

  &__icon {
    min-width: 69px;
    max-width: 69px;
    min-height: 69px;
    max-height: 69px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    @include mobile {
      margin-right: 30px;
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 21px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.619;
  }

  &__content {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.75;
  }
}

.widgets--l3 {
  padding-top: 40px;

  @include desktops {
    padding-top: 75px;
  }
}

.widget--content-l3 {
  position: relative;
  flex-direction: column;
  text-align: center;
  margin-bottom: 40px;

  @include tablet {
    padding: 0 60px;
  }

  @include desktops {
    padding: 0 30px;
  }

  @include large-desktops {
    padding: 0 60px;
  }

  @include extra-large-desktops {
    padding: 0 90px;
  }

  &:before {
    position: absolute;
    right: 0;
    top: 15%;
    transform: translateX(50%);
    width: 200px;
    height: 1px;
    background-color: #262729;
    opacity: 0.1;

    @include media-breakpoint-up(md) {
      content: "";
    }

    @include large-desktops {
      width: 250px;
    }
  }

  &__circle-content {
    min-width: 65px;
    max-width: 65px;
    min-height: 65px;
    max-height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 45px;
    font-size: 21px;
    font-weight: 500;
    line-height: 30px;
  }

  &__title {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.42857;
    margin-bottom: 25px;
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.875;
    margin-bottom: 0;
  }
}

.widget--service {
  margin-bottom: 30px;
  flex-direction: column;

  @include desktops {
    margin-bottom: 0;
  }

  .widget__icon {
    min-width: 65px;
    min-height: 65px;
    max-width: 65px;
    max-height: 65px;
    border-radius: 50%;
    position: relative;
    margin-bottom: 65px;

    img {
      position: absolute;
      bottom: -18%;
      right: -22%;
    }

    &--golden-tainoi {
      background-color: $golden-tainoi;
    }

    &--ice-cold {
      background-color: $ice-cold;
    }

    &--anakiwaap {
      background-color: $anakiwaap;
    }
  }

  .widget__heading {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 34px;
    margin-bottom: 16px;
  }

  .widget__description {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 28px;
  }
}

/*
  Home 4 : widget -- Content One Section
------------------------------------------*/

.widgets__single {
  margin-bottom: 30px;

  &__heading {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 30px;
    color: var(--color-headings-2);
  }

  &__icon {
    min-width: 60px;
    max-width: 60px;
    min-height: 60px;
    max-height: 60px;
    border-radius: 50%;
    background-color: #ff1e38;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
    box-shadow: 0 4px 65px rgba(249, 58, 112, 0.4);
  }

  &__content {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 28px;
    color: var(--color-texts-3);
  }
}

/*
  Home 4 : widget -- Content Two Section
------------------------------------------*/
.widget--content-l4 {
  display: block;
  margin-bottom: 30px;
  transition: 0.4s;

  @include desktops {
    margin-bottom: 0;
  }

  @include extra-large-desktops {
    margin-left: 80px;
  }

  .widget--content-l3__circle-content {
    margin: 0 0;
    margin-bottom: 35px;
    transition: 0.4s;
  }

  &__title {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 30px;
    margin-bottom: 25px;
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 30px;
    color: var(--color-texts-3);
  }
  &:hover,
  &.active{
    
    .widget--content-l3__circle-content{
      background-color: $torch-red !important;
      color: $white !important;
    }
  }
}

/*
  Home 5 : widget -- Content One Section
------------------------------------------*/
.widgets--content-1-l5 {
  margin-bottom: 30px;

  @include extra-large-desktops {
    margin-bottom: 53px;
  }

  .widgets__title {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 30px;
    color: $primary;
    margin-bottom: 13px;
  }

  .widgets__content {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 30px;
    @include extra-large-desktops{
      font-size: 16px;
    }
  }
}

/*
  Home 6 : widget -- About Us Section
------------------------------------------*/
.widget--about {
  display: block;
}
.widgets--about-l6 {
  margin-bottom: 30px;

  @include extra-large-desktops {
    margin-bottom: 60px;
  }

  .widgets__icon {
    min-width: 60px;
    max-width: 60px;
    min-height: 60px;
    max-height: 60px;
    background-color: #ffffff;
    color: $readical-red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 15px;
    transition: 0.4s;
  }
  .widgets__title {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 30px;
    color: var(--color-headings-2);
  }

  .widgets__text {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 30px;
  }
  &:hover {
    .widgets__icon {
      background-color: $readical-red;
      color: $white;
    }
  }
}
.widgets-align-right {
  .widgets__icon {
    order: 2 !important;
    margin-right: 0;
    margin-left: 15px;
  }
  .widgets__content {
    order: 1 !important;
    text-align: right;
  }
}

/*
  Home 6 : widget -- Content One Section
------------------------------------------*/
.widget--content-l6 {
  display: block;
  margin-bottom: 30px;

  @include desktops {
    margin-bottom: 0;
  }

  .widget--content-l6__circle {
    margin: 0 0;
    margin-bottom: 32px;
    font-size: 20px;
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 30px;
    margin-bottom: 5px;
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 30px;
    color: var(--color-texts-3);
  }
}

/*
  Home 7 : widget -- Services Sections
------------------------------------------*/

.service-area--l7 {
  .widgets {
    background: $athens-gray;
    margin-bottom: 25px;
    border-radius: 15px;
    padding-left: 35px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    @include tablet {
      padding-left: 55px;
      padding-right: 45px;
      padding-top: 40px;
      padding-bottom: 45px;
    }
    &__img {
      margin-right: 30px;
    }
    &__heading {
      font-size: 21px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 30px;
      color: $shark;
    }
    &__text {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: normal;
      line-height: 32px;
      color: rgba($shark, 0.7);
    }
  }
}

/*
  Home 9 : widget -- Content One
------------------------------------------*/

.widget--content-l9 {
  position: relative;
  flex-direction: column;
  margin-bottom: 40px;
  @include desktops{
    margin-right: 30px;
  }

  &__circle-content {
    min-width: 57px;
    max-width: 57px;
    min-height: 57px;
    max-height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 45px;
    font-size: 21px;
    font-weight: 500;
    line-height: 30px;
    transition: 0.4s;
  }

  &__title {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.42857;
    margin-bottom: 25px;
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.875;
    margin-bottom: 0;
  }

  &:hover,
  &.active{
    .widget--content-l9__circle-content{
      background-color: $yellow-3 !important;
    }
  }
}
