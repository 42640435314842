/* ======= Brand Area ===== */
.brand-area--l2 {
    padding-top: 50px;
    padding-bottom: 0px;

    @include tablet {
        padding-top: 100px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 123px;
        padding-bottom: 135px;
    }
}

.brand-group-image-l2 {
    // transform: scale(.6);
    margin-left: -22px;

    @include mobile-lg {
        transform: scale(0.8);
    }

    @include tablet {
        margin-top: 40px;
        transform: scale(1);
    }

    @include desktops {
        margin-top: 100px;
    }
}
.brand-image-group--l2 {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 503px;
    transform: scale(0.9);
    margin-top: 30px;

    @include tablet {
        transform: scale(0.9);
    }

    @include desktops {
        transform: scale(1);
        margin-top: 90px;
    }

    &__single {
        background-color: $white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    &__image--main {
        width: 5%;
        position: relative;
        border: 6px solid #ffffff;
        background-color: #ff5722;
        border-radius: 50%;
        z-index: 1;
    }

    /* Images */
    &__img-1 {
        position: absolute;
        left: 37%;
        top: 7%;
        width: 10%;
        z-index: 2;

        @include mobile {
            left: 47%;
        }
    }

    &__img-2 {
        position: absolute;
        right: -8%;
        top: 16%;
        width: 11%;
        z-index: 1;

        @include tablet {
            right: -12%;
            top: 10%;
        }
    }

    &__img-3 {
        position: absolute;
        right: -10%;
        top: 50%;
        transform: translateY(-50%);
        width: 11%;
        z-index: 1;

        @include mobile-lg-only {
            right: -9%;
        }
        @include tablet {
            right: -28%;
        }
    }

    &__img-4 {
        position: absolute;
        right: 0;
        bottom: 20%;
        width: 10%;
        z-index: 1;
    }

    &__img-5 {
        position: absolute;
        left: 38%;
        bottom: 10%;
        width: 10%;
        z-index: 1;

        @include mobile {
            left: 47%;
            bottom: 0;
        }
    }

    &__img-6 {
        position: absolute;
        left: 0;
        bottom: 18%;
        width: 12%;
        z-index: 1;

        @include mobile {
            bottom: 12%;
        }
    }

    &__img-7 {
        position: absolute;
        left: -5%;
        top: 50%;
        transform: translateY(-50%);
        width: 10%;
        z-index: 1;

        @include mobile-lg-only {
            left: -7%;
        }

        @include tablet {
            left: -22%;
        }
    }

    &__img-8 {
        position: absolute;
        left: -7%;
        top: 16%;
        width: 10%;
        z-index: 1;

        @include mobile {
            top: 10%;
        }
    }

    /* Image Line */
    &__line-1 {
        position: absolute;
        left: 48%;
        top: 26%;
        z-index: 0;
        width: 3.7%;

        @include mobile {
            left: 52%;
        }
    }

    &__line-2 {
        position: absolute;
        right: 0;
        top: 27%;
        width: 42%;

        @include mobile {
            top: 22%;
            width: 40%;
        }
    }

    &__line-3 {
        position: absolute;
        right: -9%;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
    }

    &__line-4 {
        position: absolute;
        bottom: 29%;
        right: 13%;
        width: 33%;
    }

    &__line-5 {
        position: absolute;
        right: 47%;
        bottom: 23%;
        width: 4%;

        @include mobile {
            right: 45%;
            bottom: 15%;
        }
    }

    &__line-6 {
        position: absolute;
        left: 9%;
        bottom: 29%;
        width: 35%;
        @include mobile {
            bottom: 24%;
        }
    }

    &__line-7 {
        position: absolute;
        left: -5%;
        top: 50%;
        transform: translateY(-50%);
        width: 47%;
    }

    &__line-8 {
        position: absolute;
        left: 7%;
        top: 28%;
        width: 35%;

        @include mobile {
            top: 22%;
        }
    }
}
