.form-block,
.coming-soon {
    .form-img {
        left: 0;
        top: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 40%;
        min-height: 100vh;
        @include large-desktops {
            width: 45%;
        }
    }
    .section-title {
        &__heading {
            margin-bottom: 18px;
        }
    }
    .form-box {
        @include desktops {
            margin-right: 25px;
        }
        @include large-desktops {
            margin-right: 40px;
        }
        form {
            input {
                height: 56px !important;
                margin-bottom: 24px;
            }
            .form-check {
                margin-top: 40px;
                &-label {
                    font-size: 18px;
                    font-weight: 400;
                    letter-spacing: normal;
                    line-height: 32px;
                    color: var(--color-headings-2);
                    margin-left: 11px;
                }
            }
        }
        .buttons {
            p,
            a {
                font-size: 18px;
                font-weight: 400;
                letter-spacing: normal;
                line-height: 32px;
                color: var(--color-headings-2);
                margin-left: 11px;
            }
        }
        &--reset{
            margin-right: 123px;
        }
    }
}
