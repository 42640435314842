.skillbar-wrapper {
    @include desktops {
        padding-right: 15%;
    }
}

.skillbar {
    position: relative;
    display: inline-block;
    margin-bottom: 37px;
    width: 100%;
    border-radius: 3px;
    width: 100%;

}

.skillbar-lable {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 25px;
    margin-bottom: 14px;
}

.skillbar-box {
    height: 2px;
    background-color: rgba($shark, 0.1);
    border-radius: 3px;
    position: relative;
}

.skillbar-bar {
    height: 5px;
    width: 0px;
    background: #6adcfa;
    display: inline-block;
    border-radius: 15px;
    position: absolute;
    left: 0;
    top: -1.2px;

    &--java {
        background-color: $java;
    }
}