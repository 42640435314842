.card--content {
    border: none;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: 0 24px 94px rgba(239, 67, 57, 0.4);
    border-radius: 5px;
    width: 70%;

    @include mobile {
        margin-left: auto;
    }

    @include desktops {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 53px;
        padding-right: 30px;
    }

    .card-head {
        margin-bottom: 12px;

        &__count {
            font-size: 40px;
            font-weight: 500;
            letter-spacing: -2px;
            line-height: 1.1;
            margin-bottom: 0;
            margin-right: 12px;

            @include mobile-lg {
                font-size: 50px;
            }

            @include tablet {
                font-size: 60px;
            }

            @include desktops {
                font-size: 70px;
            }
        }

        &__icon {
            min-width: 38px;
            max-width: 38px;
            min-height: 38px;
            max-height: 38px;
            border-radius: 50%;
            background-color: $ebony-clay;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 13px;
        }
    }

    .card-body__description {
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1.5;

        @include mobile-lg {
            font-size: 18px;
        }

        @include tablet {
            font-size: 21px;
        }

        @include desktops {
            font-size: 24px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.card--content-2 {
    box-shadow: 0 24px 94px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 1;
    padding-bottom: 22px;
    border: none;

    @include desktops {
        margin: 0 15px;
    }

    &__currency {
        font-weight: 500;
        letter-spacing: normal;
        line-height: 1.47619;
        color: $ebony-clay;
        font-size: 32px;

        @include tablet {
            font-size: 35px;
        }

        @include desktops {
            font-size: 42px;
        }
    }

    &__text {
        color: rgba($ebony-clay, 0.7);
        font-size: 18px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1.66666;
        margin-bottom: 0;
    }

    .card--content-single {
        margin-left: 55px;
        margin-right: 55px;
        padding-bottom: 35px;
        padding-top: 30px;
    }

    .card--content-single--highlight {
        box-shadow: 0 10px 20px rgba(34, 204, 116, 0.25);
        border-radius: 10px;
        margin-bottom: 0;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 20px;
        padding-bottom: 30px;
        margin: 0 22px;
        background-color: #fff;

        h2 {
            color: $white;
        }

        p {
            color: $white;
        }
    }
}

/*
  Home 3 : Testimonial Area
---------------------------------*/

.card-testimonial--l3 {
    background: $white;
    border: 0;
    color: $shark;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 15px;
    transition: 0.4s;

    .card-user-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        margin-top: 29px;
        border-top: 1px solid rgba(38, 39, 41, 0.1);

        .card-body__user,
        .review-star {
            padding-bottom: 15px;
        }

        .card-body__user {
            padding-right: 15px;
        }
    }

    .card-image {
        width: 70px;
        height: 70px;
        border-radius: 500px;
        margin-top: -35px;
        width: 100%;
    }

    .card-body {
        padding-top: 15px;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 15px;

        p {
            color: inherit;
        }
    }

    .card-description {
        color: inherit;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
    }

    .card-title {
        color: inherit;
        font-size: 15px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: 26px;
        text-align: left;
        margin-bottom: 3px;
    }

    .card-text--ext {
        color: inherit;
        opacity: 0.7;
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 0;
    }

    &:hover {
        background-color: #5034fc;
        color: #fff;

        @include media-breakpoint-up(lg) {
            box-shadow: 0 23px 45px rgba(80, 52, 252, 0.25);
        }

        .card-user-block {
            border-top: 1px solid rgba(255, 255, 255, 0.1);
        }
    }
}

.card--service {
    border-radius: 10px;
    background-color: #ffffff;
    padding-top: 40px;
    padding-left: 45px;
    padding-right: 35px;
    padding-bottom: 20px;
    margin-bottom: 24px;
    transition: 0.4s;
    display: inline-block;
    width: 100%;
    min-height: 265px;
    border-color: transparent;

    &:hover {
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 48px;
        max-width: 48px;
        min-height: 48px;
        max-height: 48px;
        border-radius: 10px;
        margin-bottom: 30px;
        font-size: 16px;
    }

    &__title {
        color: #262729;
        font-size: 21px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 30px;
        display: block;
        height: 52px;
        margin-bottom: 26px;
        transition: 0.4s;
    }

    &__content {
        opacity: 0.7;
        color: #262729;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 28px;
    }
}

.card--service-more {
    border-radius: 10px;
    background-color: #ffffff;
    padding-top: 40px;
    padding-left: 45px;
    padding-right: 45px;
    padding-bottom: 20px;
    margin-bottom: 24px;
    transition: 0.4s;
    display: inline-block;
    width: 100%;
    min-height: 265px;
    position: relative;

    &:before {
        content: "";
        background-image: url(../image/home-3/services-more-shape.png);
        position: absolute;
        top: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        right: -60%;
    }

    .card--service__title {
        color: var(--color-headings);
        font-size: 28px;
        letter-spacing: normal;
        line-height: 1.57142;
        height: 40px;
    }

    .card--service__text {
        color: var(--color-headings);
        margin-bottom: 0;
    }

    .btn {
        span i {
            color: $white;
        }
    }
}

/*
  Home 4 : Services Area
---------------------------------*/

.card--services-l4 {
    border: none;
    padding-top: 35px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 51px;
    margin-bottom: 25px;
    border-radius: 15px;
    transition: 0.4s;
    @include mobile-lg {
        padding-left: 60px;
        padding-right: 60px;
    }
    @include tablet {
        padding-left: 30px;
        padding-right: 30px;
    }
    @include desktops {
        padding-left: 60px;
        padding-right: 60px;
    }

    &__icon {
        min-width: 60px;
        max-width: 60px;
        min-height: 60px;
        max-height: 60px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 30px;
    }

    &__heading {
        font-size: 21px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 30px;
        color: $shark;
    }

    &__content {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1.86666;
        margin-bottom: 26px;
        color: rgba($shark, 0.7);
    }

    .btn-link {
        font-size: 15px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: 26px;
        display: flex;
        align-items: center;
        color: $shark;
        transition: 0.4s;

        i {
            margin-left: 10px;
        }
    }

    &:hover{
       box-shadow:  0 10px 30px rgba(0, 0, 0, 0.08);
    }
}

/*
  Home 4 : Pricing Table
---------------------------------*/

.card--table-single {
    padding-bottom: 45px;
    border: none;
    border-radius: 15px;
    transition: 0.4s;
    margin-bottom: 25px;

    &__list {
        margin-bottom: 38px;
        padding-left: 30px;
        padding-right: 30px;

        li {
            color: rgba($shark, 0.7);
            font-size: 16px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 30px;
            margin-bottom: 5px;
        }
    }

    .table-top {
        padding-bottom: 40px;
        padding-top: 35px;
        padding-left: 30px;
        padding-right: 30px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        &__title {
            font-size: 24px;
            font-weight: 500;
            letter-spacing: normal;
            line-height: 40px;
            margin-bottom: 8px;
            color: $shark;
        }

        &__text {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 30px;
            color: rgba($shark, 0.7);
        }

        .card--price {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin-top: 35px;

            &__price {
                color: $shark;
                font-size: 70px;
                font-weight: 500;
                line-height: 1;
                letter-spacing: -1px;
                margin-bottom: 0;
                transition: 0.4s;
            }

            &__price-time,
            &__currency {
                font-size: 16px;
                font-weight: 500;
                line-height: 28px;
                letter-spacing: normal;
                color: $shark;
                transition: 0.4s;
            }

            &__currency {
                margin-top: 8px;
            }
        }
    }

    .btn {
        max-width: 180px;
        height: 56px;
        border-radius: 50px;
        margin: 0 auto;
        opacity: 0.7 !important;
        transition: 0.4s;
    }

    &:hover {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);

        .btn {
            opacity: 1 !important;
        }

        .card--price {
            &__price,
            &__price-time,
            &__currency {
                color: $electric-violet-3;
            }
        }
    }
}

/*
  Home 4 : Testimonial
---------------------------------*/
.card-testimonial--l4 {
    background: $white;
    border: 0;
    color: $shark;
    border-radius: 15px;
    transition: 0.4s;
    margin: 0 20px;

    @include mobile-lg {
        margin: 0 0;
        margin-right: 30px;
    }

    .card-image {
        min-width: 50px;
        max-width: 50px;
        min-height: 50px;
        max-height: 50px;
        border-radius: 500px;
        width: 100%;
        margin-right: 10px;
        margin-bottom: 10px;
        @include mobile {
            margin-bottom: 0;
        }

        img {
            width: 100%;
        }
    }

    .card-icon {
        color: #6001d3;
        font-size: 36px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: normal;
        transition: 0.4s;
    }

    .card-body {
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
        @include mobile-lg {
            padding-top: 45px;
            padding-left: 45px;
            padding-right: 45px;
            padding-bottom: 40px;
        }
        &__top {
            margin-bottom: 20px;
        }

        p {
            color: inherit;
            margin-bottom: 0;
        }
    }

    .card-description {
        color: inherit;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
    }

    .card-title {
        font-size: 15px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 1.73333;
        color: $shark;
        margin-bottom: 0;
        transition: 0.4s;
    }

    .card-text--ext {
        font-size: 13px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 22px;
        color: rgba($shark, 0.7);
    }

    &:hover {
        background-color: #5034fc;
        color: #fff;

        .card-title,
        .card-icon {
            color: $white;
        }

        @include media-breakpoint-up(lg) {
            box-shadow: 0 23px 45px rgba(80, 52, 252, 0.25);
        }

        .card-user-block {
            border-top: 1px solid rgba(255, 255, 255, 0.1);
        }
    }
}

/*
  Home 5 : Services Area
---------------------------------*/

.card--services-l5 {
    border: none;
    padding-top: 35px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 31px;
    margin-bottom: 25px;
    border-radius: 15px;
    transition: 0.4s;
    @include mobile {
        padding-top: 35px;
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 51px;
    }
    @include tablet {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 31px;
        margin-bottom: 25px;
    }
    @include desktops {
        padding-top: 35px;
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 51px;
    }

    &__icon {
        min-width: 60px;
        max-width: 60px;
        min-height: 60px;
        max-height: 60px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 15px;
    }

    &__heading {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 1.666666;
        color: $shark;
        margin-bottom: 17px;
    }

    &__content {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 30px;
        margin-bottom: 20px;
        color: rgba($shark, 0.7);
    }

    .btn-link {
        font-size: 15px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: 26px;
        display: flex;
        align-items: center;
        color: $shark;
        transition: 0.4s;

        i {
            margin-left: 10px;
        }
    }

    &:hover {
        box-shadow: -3px 25px 50px rgba(23, 3, 48, 0.12);
    }
}

/*
  Home 5 : Pricing Table
---------------------------------*/
.table-section--l5,
.table-section--l6,
.table-section--inner-2 {
    .card--table-single {
        transition: 0.4s;

        &:hover {
            box-shadow: -3px 25px 50px rgba(23, 3, 48, 0.12);
        }

        .table-top {
            padding-bottom: 25px;

            &__title {
                color: $white;
                font-size: 32px;
                font-weight: 500;
                font-style: normal;
                letter-spacing: normal;
                line-height: 1.3125;
                margin-bottom: 18px;
            }

            &__text {
                color: rgba($white, 0.7);
                font-size: 18px;
                font-weight: 400;
                letter-spacing: normal;
                line-height: 32px;
                margin-bottom: 0;
            }

            .card--price {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: -15px;
                    background-color: rgba($white, 0.3);
                    width: 190px;
                    height: 1px;
                    transform: translateX(-50%);
                }

                &__currency,
                &__price-time {
                    color: $white;
                    font-size: 13px;
                    font-weight: 500;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: 22px;
                }
                &__currency {
                    margin-top: 20px !important;
                }
                &__price-time {
                    margin-bottom: 9px;
                }

                &__price {
                    color: $white;
                    padding-top: 0px;
                }
            }
        }

        &__list {
            margin-bottom: 22px;
            margin-top: 45px;

            li {
                font-size: 18px;
                font-weight: 400;
                letter-spacing: normal;
                margin-bottom: 13px;
            }
        }

        .btn {
            opacity: 1 !important;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}

/*
  Home 5 : Testimonial Area
---------------------------------*/

.card-testimonial--l5 {
    background: $white;
    border: 0;
    color: $shark;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 25px;
    padding-bottom: 0;
    border-radius: 15px;
    transition: 0.4s;
    margin: 0 15px;
    @include desktops {
        padding-left: 45px;
        padding-right: 45px;
        padding-top: 38px;
        padding-bottom: 10px;
    }
    @include large-desktops {
        margin: 0;
    }

    .card-user-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 15px;

        .card-body__user,
        .review-star {
            padding-bottom: 15px;
        }

        .card-body__user {
            padding-right: 15px;
        }
    }

    .card-image {
        min-width: 60px;
        max-width: 60px;
        min-height: 60px;
        max-height: 60px;
        border-radius: 500px;
        width: 100%;
    }

    .card-body {
        padding-top: 25px;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 15px;

        .card-description {
            font-size: 18px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 1.777777;
            margin-bottom: 20px;
            color: inherit;
            @include large-desktops {
                padding-right: 30px;
            }
        }

        .card-title {
            color: inherit;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: normal;
            line-height: 1.388888;
            margin-bottom: 0px;
        }

        .card-text--ext {
            color: inherit;
            opacity: 0.7;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.866666;
            margin-bottom: 0;
        }
        .review-star {
            i {
                color: $yellow;
            }
        }
    }

    &:hover {
        box-shadow: -3px 25px 50px rgba(23, 3, 48, 0.12);
    }
}

/*
  Home 5 : Table Area
---------------------------------*/
.table-section--l5,
.table-section--inner-2 {
    .table-top {
        .card--price {
            &__currency {
                margin-top: 17px !important;
            }
            &__price {
                font-size: 70px;
                font-weight: 500;
                letter-spacing: normal;
                line-height: 1.3;
            }
            &__price-time {
                margin-bottom: 20px !important;
            }
        }
    }
    .card--table-single__list {
        li {
            line-height: 1.77777;
        }
    }
    .btn{
        &:hover{
            opacity: 1 !important;
            transform: translateY(-5px);
        }
    }
}

/*
  Home 6 : Services Area
---------------------------------*/

.card--services-l6 {
    border: none;
    padding-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 33px;
    margin-bottom: 25px;
    border-radius: 15px;
    transition: 0.4s;
    background-color: transparent;

    &__icon {
        min-width: 65px;
        max-width: 65px;
        min-height: 65px;
        max-height: 65px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 40px;
        transition: 0.4s;
    }

    &__heading {
        font-size: 21px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 30px;
        color: var(--color-headings);
        transition: 0.4s;
        margin-bottom: 15px;
    }

    &__content {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1.86666;
        margin-bottom: 30px;
        color: var(--color-texts-3);
        transition: 0.4s;
        margin-bottom: 0;
    }
   
}

/*
  Home 6 : Pricing Table
---------------------------------*/
.table-section--l6 {
    .card--table-single {
        .table-top {
            padding-left: 0;
            padding-right: 0;
            &__title {
                font-size: 24px !important;
                line-height: 40px !important;
                margin-bottom: 2px;
            }
            .card--price {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: -20px;
                    background-color: rgba($white, 0.3);
                    width: 100% !important;
                    height: 1px;
                    transform: translateX(-50%);
                }
            }
        }

        .list-checked {
            li {
                position: relative;
                text-align: left;
                padding-left: 55px;
                &:before {
                    content: "\f00c";
                    font-family: "Font Awesome 5 Free";
                    position: absolute;
                    top: 0;
                    left: 20px;
                    font-weight: 700;
                    color: $readical-red;
                }
            }
        }

        &:hover {
            box-shadow: 0 4px 55px rgba(0, 0, 0, 0.2);
        }
        .btn{
            &:hover{
                transform: translateY(-5px);
            }
        }
    }
}

/*
  Home 6 : Testimonial
---------------------------------*/

.testimonial-section--l6 {
    .card-testimonial--l4 {
        .card-icon {
            color: $readical-red;
        }

        &:hover {
            .card-icon {
                color: $white;
            }
        }
    }
}

/*
  Home 8 : Testimonial
---------------------------------*/

.testimonial-section--l8 {
    .card-testimonial--l4 {
        .card-icon {
            color: $niagara;
        }

        &:hover {
            .card-icon {
                color: $white;
            }
        }
    }
}

/*
  Home 8 : Pricing Table
---------------------------------*/
.table-section--l8 {
    .list-checked {
        &--bittersweet {
            li:before {
                color: $bittersweet !important;
            }
        }
        &--niagara {
            li:before {
                color: $niagara !important;
            }
        }
    }
}

/*
 Career Details
---------------------------------*/
.card-jobs {
    box-shadow: 0 4px 56px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    border: 1px solid rgba(181, 181, 181, 0.2);
    background-color: $white;
    padding: 30px 30px;
    @include mobile-lg {
        padding: 26px 50px;
    }
    .card-jobs__content {
        &__heading {
            font-size: 32px;
            font-weight: 500;
            letter-spacing: normal;
            line-height: 1.38095;
            color: $shark;
            margin-bottom: 10px;
            @include tablet {
                font-size: 35px;
                margin-bottom: 20px;
            }
            @include desktops {
                font-size: 42px;
            }
        }
        &__details {
            margin: 0;
            padding: 0;
            li {
                a {
                    font-size: 18px;
                    font-weight: 400;
                    letter-spacing: normal;
                    line-height: 32px;
                    color: rgba($shark, 0.7);
                    margin-right: 15px;
                    i {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}

/*
  Home 9 : Services Area
---------------------------------*/

.card--services-l9 {
    background-color: transparent;
    border: none;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 33px;
    margin-bottom: 25px;
    border-radius: 15px;
    transition: 0.4s;
    @include mobile-lg {
        padding-left: 5px;
        padding-right: 5px;
    }
    @include tablet {
        padding-left: 30px;
        padding-right: 30px;
    }
    @include desktops {
        padding-left: 10px;
        padding-right: 10px;
    }
    @include large-desktops {
        padding-left: 5px;
        padding-right: 5px;
    }
    @include extra-large-desktops {
        padding-left: 20px;
        padding-right: 20px;
    }

    &__icon {
        min-width: 65px;
        max-width: 65px;
        min-height: 65px;
        max-height: 65px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 40px;
        transition: 0.4s;
        i {
            transition: 0.4s;
        }
    }

    &__heading {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 40px;
        color: var(--color-headings);
        transition: 0.4s;
        margin-bottom: 15px;
    }

    &__content {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 30px;
        color: var(--color-texts-3);
        transition: 0.4s;
        margin-bottom: 0;
    }
}

.testimonial-section--l9 {
    .card {
        margin-top: 30px;
        margin-right: 30px;
        border: none;
        border-radius: 15px;
        position: relative;
        z-index: 0;

        &-body{
            padding-top: 55px;
            padding-bottom: 25px;
            padding-left: 40px;
        }
        &__icon {
            max-width: 60px;
            min-width: 60px;
            max-height: 60px;
            min-height: 60px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: $white;
            background-color: $electric-violet-2;
            border-radius: 50%;
            font-size: 20px;
            position: absolute;
            top: -30px;
            z-index: 9;
        }
        &__content {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 30px;
            margin-bottom: 25px;
            color: $shark;
        }
        &__meta {
            &__image{
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 15px;
                img{
                    width: 100%;
                }
            }
            &__title {
                font-size: 18px;
                font-weight: 500;
                letter-spacing: normal;
                line-height: 25px;
                margin-bottom: 0;
                color: $shark;
            }
            &__text {
                font-size: 16px;
                font-weight: 400;
                letter-spacing: normal;
                line-height: 30px;
                margin-bottom: 0;
                color: rgba($shark, 0.7);
            }
        }
    }
}
