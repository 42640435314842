.jobs-area {
    padding-top: 55px;
    padding-bottom: 60px;
    @include tablet {
        padding-top: 75px;
        padding-bottom: 80px;
    }
    @include desktops {
        padding-top: 110px;
        padding-bottom: 95px;
    }
}
.jobs-filter {
    &__title {
        font-size: 21px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 1.4285;
        margin-bottom: 30px;
    }
    &__menu {
        padding: 0;
        margin: 0;
        border-right: 1px solid rgba(181, 181, 181, 0.2);
        display: block;
        button {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: normal;
            line-height: 28px;
            color: var(--color-headings-2);
            display: block;
            transition: 0.4s;
            position: relative;
            padding-left: 20px;
            margin-bottom: 17px;
            background-color: transparent;
            border: none;
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 7px;
                height: 7px;
                background-color: $shark;
                border-radius: 50%;
                transition: 0.4s;
            }
            &:hover {
                color: $electric-violet-2;
                &::before {
                    background-color: $electric-violet-2;
                }
            }
        }
    }
}

.jobs-area__tab {
    .card {
        display: inherit;
        &--single {
            border-radius: 10px;
            border: 1px solid rgba(181, 181, 181, 0.2);
            border-radius: 10px;
            background-color: $white;
            margin-bottom: 25px;
            padding-top: 35px;
            padding-bottom: 40px;
            padding-left: 50px;
            padding-right: 35px;
            &:hover {
                box-shadow: 0 4px 56px rgba(0, 0, 0, 0.07);
                border: 1px solid rgba(181, 181, 181, 0.2);
            }

            .btn {
                min-width: 127px;
                height: 50px;
                display: inline-flex;
                margin-top: 7px;
            }
        }
        &__heading {
            font-size: 28px;
            font-weight: 500;
            letter-spacing: normal;
            line-height: 1.57142;
            margin-bottom: 25px;
            color: $shark;
        }
        &__list {
            li {
                font-size: 18px;
                font-weight: 400;
                letter-spacing: normal;
                line-height: 1.777777;
                margin-bottom: 23px;
                color: rgba($shark, 0.7);
                i {
                    margin-right: 10px;
                }
            }
        }
    }
}
