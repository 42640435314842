.terms-area {
    padding-top: 20px;
    padding-bottom: 20px;
    @include tablet{
        padding-top: 60px;
        padding-bottom: 60px;
    }
    @include desktops {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .terms-content {
        @include large-desktops {
            padding: 0 43px;
        }
        @include extra-large-desktops {
            padding: 0 53px;
        }
        &__heading {
            font-size: 21px;
            font-weight: 500;
            letter-spacing: normal;
            line-height: 1.42857;
            color: var(--color-headings-2);
            margin-bottom: 15px;
            @include desktops{
                margin-bottom: 25px;
            }
        }
        &__text {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 1.777777;
            color: var(--color-texts-3);
            @include desktops {
                font-size: 18px;
            }
        }
        .terms-content__box {
            padding-top: 10px;
            padding-bottom: 20px;
        }
        .terms-list {
            li {
                font-size: 16px;
                font-weight: 400;
                letter-spacing: normal;
                line-height: 1.777777;
                color: var(--color-texts-3);
                @include desktops {
                    font-size: 18px;
                }
            }
        }
    }
}
