/*
  Home 1 : Feature Section
------------------------------*/
.feature-section {
    position: relative;
    overflow: hidden;
    padding-top: 55px;
    padding-bottom: 20px;

    @include tablet {
        padding-top: 70px;
        padding-bottom: 40px;
    }

    @include desktops {
        padding-top: 125px;
        padding-bottom: 105px;
    }

    .feature-area-tab {
        margin-top: 35px;

        @include media-breakpoint-up(md) {
            margin-top: 80px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 148px;
        }

        .nav {
            // padding-right: 65px;
            @include media-breakpoint-up(lg) {
                padding-right: 20px;
            }

            @include media-breakpoint-up(xl) {
                padding-right: 65px;
            }

            @include media-breakpoint-up(xxl) {
                padding-right: 110px;
            }
        }

        .tab-content {
            @include media-breakpoint-up(lg) {
                padding-right: 20px;
            }

            @include media-breakpoint-up(xl) {
                padding-left: 26px;
            }
        }
    }

    &--l1 {
        &__shape-group {
            position: absolute;
            right: 0;
            top: -7%;
            width: 30%;
            z-index: 1;

            .img-1 {
                width: 50%;
                position: absolute;
                top: 30%;
                right: -30%;
                z-index: -1;
            }
        }
    }
}

.features-widgets {
    margin-top: 35px;

    @include tablet {
        margin-top: 80px;
    }

    @include desktops {
        margin-top: 135px;
    }
}

.widget--feature {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.666666;
    margin-bottom: 20px !important;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    color: $white;
    transition: 0.4s;

    @include tablet {
        width: 194px;
        height: 60px;
        margin-bottom: 55px !important;
    }

    i {
        margin-right: 10px;
    }

    &:hover,
    &.active {
        background-color: $white;
        color: $blue-ribbon;
        border-radius: 10px;

        i {
            color: $primary;
        }
    }
}

.features-content {
    @include tablet {
        padding-left: 40px;
    }
}

.features-content__item {
    margin-bottom: 35px;

    &__count {
        color: rgba(255, 255, 255, 0.3);
        font-size: 60px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: -2px;
        line-height: 1.1;
        margin-bottom: 25px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 52px;
        }
    }

    &__heading {
        font-size: 21px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 1.619047;
        margin-bottom: 25px;
    }

    &__content {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1.66666;
        margin-bottom: 0;
    }
}

/* ========== Home 2 =========== */

// .feature-tab {
//     &__wrap {
//         text-align: center;
//         margin: 0 auto;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         min-width: 51%;
//         transition: 0.4s;
//         border-bottom: 2px solid rgba($white-lilac, 0.2);
//         margin-bottom: 60px;

//         @include desktops {
//             margin-bottom: 115px;
//         }

//         .nav-link {
//             border: none;
//             font-size: 18px;
//             font-weight: 400;
//             letter-spacing: normal;
//             line-height: 1.66666;
//             display: inline-flex;
//             color: var(--color-texts);
//             padding: 18px 8px;
//             transition: 0.4s;
//             border-bottom: 2px solid transparent;
//             justify-content: space-around;

//             &.active {
//                 background-color: transparent;
//                 color: $golden-tainoi;
//                 border-bottom: 2px solid $golden-tainoi;
//             }
//         }
//     }
// }

/*
  Home 2 : Feature Section
---------------------------------*/

.feature-area--l2 {
    padding-top: 35px;
    padding-bottom: 40px;

    @include tablet {
        padding-top: 55px;
        padding-bottom: 60px;
    }

    @include desktops {
        padding-top: 85px;
        padding-bottom: 127px;
    }

    &__image-group {
        position: relative;
        z-index: 1;
        box-shadow: 0 28px 34px rgba(37, 55, 63, 0.1);

        @include desktops {
            margin-right: 5px;
        }
        @include extra-large-desktops {
            margin-right: 25px;
        }
        &__img-1 {
            width: 20%;
            position: absolute;
            left: -10%;
            bottom: -10%;
            z-index: -1;
            img {
                width: 100%;
            }
        }
    }
}

.feature-area--l2__content {
    margin-top: 50px;

    @include tablet {
        margin-top: 0;
    }

    @include extra-large-desktops {
        margin-left: 45px;
    }

    &__heading {
        font-size: 35px;
        font-weight: 500;
        letter-spacing: -2px;
        line-height: 1.20689;
        margin-bottom: 40px;

        @include mobile {
            font-size: 38px;
        }

        @include tablet {
            font-size: 48px;
        }

        @include desktops {
            font-size: 58px;
        }
    }
}

/*
  Home 3 : Feature Section
---------------------------------*/
.feature-section--l3 {
    padding-top: 50px;
    padding-bottom: 60px;

    @include media-breakpoint-up(sm) {
        padding-top: 70px;
        padding-bottom: 80px;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 125px;
        padding-bottom: 135px;
    }

    .section-title {
        margin-bottom: 10px;
        @include desktops {
            margin-bottom: 30px;
        }
        &__sub-heading {
            margin-bottom: 20px;
        }

        &__heading {
            margin-bottom: 0px;
        }
    }
}

.feature-content--l3 {
    margin-top: 50px;

    &__image {
        @include desktops {
            margin-right: 30px;
        }
    }

    &__list {
        margin-top: 10px;
        margin-bottom: 30px;

        @include large-desktops {
            margin-bottom: 65px;
        }

        li {
            position: relative;
            z-index: 1;
            padding-left: 30px;
            margin-bottom: 20px;
            color: var(--color-headings-2);
            font-size: 16px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 1.75;

            &:before {
                content: "";
                position: absolute;
                width: 10px;
                height: 18px;
                background-color: #ff5722;
                left: 10px;
                top: 4px;
            }
        }
    }
}

.feature-counter--l3 {
    position: relative;

    @include desktops {
        left: -25%;
        min-width: 120%;
    }
}
