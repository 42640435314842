/* ==== Footer One === */
.cta-section--l1 {
    position: relative;
    padding-top: 50px;
    z-index: 0;
    border-radius: 0;

    @include tablet {
        padding-top: 70px;
    }

    @include desktops {
        padding-top: 120px;
    }

    .footer-widgets {
        &__title {
            opacity: 0.7;
        }
    }

    .newsletter-form {
        max-width: 521px;
    }
}

.footer-copyright--l1 {
    margin-top: 16px;
    border-top: 1px solid var(--border-color);

    @include tablet {
        margin-top: 70px;
    }

    @include desktops {
        margin-top: 95px;
    }
}

.newsletter--l1-2 {
    margin-top: 45px;
    margin-bottom: 50px;

    @include desktops {
        margin-bottom: 155px;
    }

    input {
        transition: 0.4s;
        background-color: rgba($white, 0.2);
        font-size: 16px;
        font-weight: 400;
        letter-spacing: normal;
        width: 100%;
        border: none;
        padding: 0 25px;

        &::placeholder {
            color: rgba($white, 0.7);
        }

        &:focus {
            background-color: #fff !important;

            &::placeholder {
                color: #000;
            }
        }
    }

    .btn {
        width: 100%;
        height: 70px;
        border-radius: 10px;

        @include mobile {
            width: 172px;
        }
    }
}

.footer-widgets {
    margin-bottom: 40px;

    @include desktops {
        margin-bottom: 0;
    }

    &__title {
        color: var(--color-texts);
        font-size: 16px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 28px;
        margin-bottom: 25px;
    }

    &__text {
        margin-top: 38px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 30px;
        color: rgba($white-lilac, 0.7);
    }

    &--address {
        margin-top: 35px !important;

        li {
            margin-bottom: 20px;
        }
    }
}

.footer-widgets__list {
    padding: 0;
    margin: 0;

    li {
        list-style: none;
        color: var(--color-headings);
        display: flex;

        a {
            color: var(--color-headings);
            font-size: 16px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 2.75;
            transition: 0.4s;

            &:hover {
                text-decoration: underline;
            }
        }

        i {
            margin-right: 5px;
            margin-top: 13px;
            font-size: 20px;
            width: 35px;
        }

        span {
            display: block;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 1.75;
        }
    }
}

.copyright {
    padding-top: 10px;
    padding-bottom: 10px;

    &__text {
        margin-bottom: 0;
        opacity: 0.7;
        color: $white;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 28px;
    }
}

.footer-social-share {
    padding-top: 11px;
    padding-bottom: 30px;

    ul {
        margin-bottom: 0;

        li {
            a {
                position: relative;
                margin: 0 8px;
                color: var(--color-headings);
                transition: 0.4s;

                &:hover {
                    color: $primary;
                }

                &::before {
                    content: ".";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transform: translateX(-20px);
                }
            }

            &:first-child {
                a:before {
                    display: none;
                }
            }
        }
    }

    &.dot-right {
        ul {
            li {
                a {
                    &::before {
                        transform: translateX(-12px);
                    }
                }
            }
        }
    }
}

/* Footer 2 */
.footer-section--2 {
    @include desktops {
        padding-bottom: 120px;
    }
}

.footer-widgets--l2 {
    .footer-widgets__text {
        color: var(--color-texts-2);
        opacity: 0.7;
    }

    .footer-widgets__title {
        color: var(--color-texts-2);
        opacity: 0.4;
    }

    .footer-widgets__list {
        li a {
            color: var(--color-texts-2);
        }
    }
}

.footer-social-share--1 {
    padding-top: 20px;
    padding-bottom: 20px;

    ul {
        li {
            a {
                margin-left: 20px;
            }
        }
    }

    &.dot-right {
        ul {
            li {
                a {
                    &::before {
                        transform: translateX(-20px);
                    }
                }
            }
        }
    }
}

.payment-gatway {
    padding-top: 40px;
}

.footer-social-share--2 {
    ul {
        li {
            a {
                margin-left: 0;
            }
        }
    }

    &.dot-right {
        ul {
            li {
                a {
                    &::before {
                        left: 7px;
                        bottom: -2px;
                        transform: translateX(-13px) !important;
                    }
                }
            }
        }
    }
}

/* Footer 2
________________________________ */

.footer-widgets--l2 {
    h4 {
        opacity: 0.4;
    }
}

/*
  Home 3 : Footer Section
---------------------------------*/
.footer-section--l3 {
    padding-top: 60px;

    @include media-breakpoint-up(sm) {
        padding-top: 90px;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 130px;
    }

    .footer-block-title {
        color: var(--color-headings-2);
        font-size: 18px;
        font-weight: 500;
        line-height: 25px;
        padding-bottom: 20px;
    }

    .footer-list-block--2 {
        @include media-breakpoint-up(xl) {
            padding-right: 50px;
        }
    }
}

.footer-widgets__list--l3 {
    margin-top: -8px;

    li {
        color: var(--color-texts-3);
        font-family: Rubik;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28px;
        text-align: left;

        a {
            color: var(--color-texts-3);
        }
    }

    &__address {
        margin-top: 0px;

        li {
            padding-bottom: 20px;
            word-break: break-word;
        }
    }
}

.footer-brand-block--l3 {
    @include large-desktops {
        padding-right: 60px;
    }

    .brand-logo {
        margin-bottom: 38px;
    }

    &__text {
        margin-bottom: 35px;
        color: $jumbo;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1.875;
    }
}

.footer-insta-gallery {
    margin-bottom: -17px;
    margin-left: -8.5px;
    margin-right: -8.5px;

    >div {
        padding-left: 8.5px;
        padding-right: 8.5px;
        padding-bottom: 17px;
    }
}

.footer-social-share--rounded {
    display: inline-flex;
    list-style: none;
    margin: 0 -10px;
    padding: 0;

    >li,
    div {
        padding: 0 10px;
    }

    a {
        min-width: 34px;
        min-height: 34px;
        border-radius: 500px;
        // box-shadow: 0 10px 20px rgba(253, 52, 110, 0.25);
        // background-color: #ff5722;
        color: var(--color-headings-2);
        font-size: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: 0.4s;

        &:hover {
            box-shadow: 0 10px 20px rgba(253, 52, 110, 0.25);
            background-color: #ff5722;
            color: #fff;
        }
    }
}

.copyright-block--l3 {
    padding-top: 35px;
    padding-bottom: 40px;
}

.copyright-text--l3 {
    color: var(--color-texts-3);
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;

    @include media-breakpoint-up(xs) {
        margin-bottom: 0;
    }
}

/*
  Home 4 : Footer Section
---------------------------------*/
.footer-section--l4,
.footer-section--l9 {
    .brand-logo {
        img {
            display: inline-flex;
        }
    }
}

.footer-menu {
    ul {
        padding: 0;
        margin: 0;

        li {
            display: inline-flex;
            align-items: center;

            a {
                color: var(--color-headings-2);
                font-size: 13px;
                font-weight: 400;
                letter-spacing: normal;
                line-height: 22px;
                display: inline-flex;
                align-items: center;
                padding: 5px 20px;

                @include tablet {
                    margin-right: -20%;
                }
            }
        }
    }
}

/*
  Home 5 : Footer Section
---------------------------------*/
.footer-section--l5 {
    padding-top: 80px;
    padding-bottom: 40px;

    .brand-logo {
        margin-bottom: 25px;

        img {
            text-align: center;
            margin: 0 auto;
        }
    }

    .footer-brand-block__text {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 28px;
        color: var(--color-texts-3);
    }

    .footer-menu {
        ul {
            li {
                a {
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: normal;
                    line-height: 30px;
                    color: var(--color-headings-2);
                    transition: 0.4s;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    .footer-social-share {
        margin-bottom: 60px;

        li {
            a {
                min-width: 36px;
                max-width: 36px;
                min-height: 36px;
                max-height: 36px;
                box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
                background-color: $white;
                color: $shark;

                &:hover {
                    box-shadow: 0 2px 2px #e32900;
                    background-color: #ff5722;
                    color: $white;
                }
            }
        }
    }

    .copyright-block--l3 {
        padding-top: 30px;

        .copyright-text--l3 {
            color: $jumbo;
        }
    }
}

/* Footer 7 */
.footer-section--7,
.footer-inner-1 {
    padding-top: 60px;
    padding-bottom: 20px;

    @include tablet {
        padding-top: 80px;
    }

    @include desktops {
        padding-top: 100px;
        padding-bottom: 20px;
    }

    .copyright {
        padding-top: 25px;

        p {
            font-size: 13px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: 22px;
        }
    }

    .footer-top {
        @include desktops {
            padding-bottom: 95px;
        }
    }
}

.footer-widgets--l7 {
    .footer-widgets__title {
        color: var(--color-headings-2);
        font-weight: 500;
    }

    .footer-widgets__text {
        color: var(--color-texts-3);
    }

    .footer-widgets__list {

        li a,
        li span {
            color: var(--color-texts-3) !important;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 1.866666;
        }

        li {
            margin-bottom: 15px;

            i {
                margin-top: 5px;
            }
        }
    }

    .footer-social-share {
        margin-right: 70px;

        li {
            display: inline-block;

            a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-width: 25px;
                min-height: 25px;
                padding-left: 0;
            }
        }
    }
}

.footer-inner-1 {
    .footer-social-share--rounded {
        li {
            a {
                &:hover {
                    background-color: transparent !important;
                    color: $electric-violet-2;
                    box-shadow: none;
                }
            }
        }
    }
}

.footer-inner-2 {
    .footer-menu {
        ul {
            margin-top: 25px;

            @include tablet {
                margin-top: 0;
            }

            li {
                a {
                    font-size: 15px;
                    font-weight: 500;
                    letter-spacing: normal;
                    line-height: 26px;
                    color: var(--color-heading-2);
                    transition: 0.4s;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    .footer-social-share {
        li {
            a {
                &:hover {
                    background-color: transparent;
                    box-shadow: none;
                    color: $primary;
                }
            }
        }
    }
}



.footer-section--l9 {
    .footer-social-share {
        li {
            a {
                &:hover {
                    background-color: transparent;
                    box-shadow: none;
                    color: $yellow;
                }
            }
        }
    }
}

.footer-map {
    position: relative;

    .card {
        background-color: $electric-violet-3;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        padding: 10px 10px;
        max-width: 142px;
        height: 77px;
        border-radius: 5px;

        p {
            margin-bottom: 0;
            color: $white;
            font-size: 11px;
            font-weight: 500;
            letter-spacing: normal;
            line-height: 20px;
        }

        &--map-1 {
            top: 20%;
            left: -6%;
            transform: scale(.7);

            @include mobile{
                left: 0;
            }
            @include mobile-lg{
                top: 25%;
                left: 3%;
                transform: scale(1);
            }
            @include tablet{
                left: 8%;
            }
        }

        &--map-2 {
            bottom: -30px;
            right: -30px;
            transform: scale(.7);
            @include mobile{
                bottom: -5%;
                right: 0;
            }
            @include mobile-lg{
                bottom: -13%;
                right: 0;
                transform: scale(1);
            }
            @include tablet{
                bottom: -3%;
            }
            @include desktops {
                bottom: 7%;
                right: 5%;
                padding: 10px 15px;
            }
        }

        &--triangle-up {

            &::before {
                content: "";
                position: absolute;
                top: -7px;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 7px solid $electric-violet-3;
            }
        }

    }
}