/* Sidebar */
.sidebar-area {
  .widget {
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0 4px 56px rgba(22, 22, 22, 0.05);
    margin-bottom: 25px;
    display: block;
    padding-top: 35px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 35px;

    &__title {
      font-size: 21px;
      font-weight: 500;
      letter-spacing: normal;
      line-height: 30px;
      margin-bottom: 20px;
      color: $shark;
    }

    &__search {
      form {
        border: 1px solid rgba(62, 73, 243, 0.1);
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;

        i {
          font-size: 18px;
          letter-spacing: normal;
          line-height: 22px;
        }
        input {
          border: none;
          height: 60px;
          width: 100%;
          padding-left: 20px;
          &:focus {
            outline: none;
          }
        }
      }
    }

    &__recent-post {
      &__single {
        border-bottom: 1px solid rgba(38, 39, 41, 0.1);
        padding-top: 20px;
        padding-bottom: 13px;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          border-bottom: 1px solid rgba(38, 39, 41, 0);
          padding-bottom: 0px;
        }
        &:hover {
          a {
            .widget__recent-post__title {
              color: $primary;
            }
          }
        }
      }

      &__title {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 25px;
        color: $shark;
        transition: 0.4s;
      }
      &__date {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 28px;
        margin-bottom: 0;
        color: rgba($shark, 0.7);
      }
    }
    &__tweets {
      padding: 0;
      margin: 0;
      li {
        
        border-bottom: 1px solid rgba(38, 39, 41, 0.1);
        padding-top: 20px;
        padding-bottom: 13px;
        transition: 0.4s;
        span {
          color: $electric-violet-2;
        }
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          border-bottom: 1px solid rgba(38, 39, 41, 0);
          padding-bottom: 0px;
          margin-bottom: 0;
        }

        &:hover {
          a{
            color: $primary;
          }
        }
        a{
          font-size: 18px;
          font-weight: 500;
          letter-spacing: normal;
          line-height: 25px;
          color: $shark;
          margin-bottom: 5px;
          display: block;
        }
      }
    }
    &__category {
      ul {
        li {
          a {
            color: rgba($shark, 0.7);
            margin-bottom: 17px;

            h4 {
              font-size: 18px;
              font-weight: 500;
              letter-spacing: normal;
              line-height: 25px;
              color: $shark;
              @include mobile-lg {
                width: 75%;
              }
            }
            span {
              font-size: 16px;
              font-weight: 400;
              letter-spacing: normal;
              line-height: 30px;
              color: rgba($shark, 0.7);
              display: block;
              position: relative;
              text-align: right;
              &:before {
                content: "";
                position: absolute;
                left: -100%;
                top: 50%;
                // transform: translateX(0);
                width: 51px;
                height: 1px;
                background-color: transparent;
              }
              @include mobile-lg {
                width: 25%;
                &:before {
                  content: "";
                  position: absolute;
                  left: -100%;
                  top: 50%;
                  // transform: translateX(0);
                  width: 51px;
                  height: 1px;
                  background-color: rgba(38, 39, 41, 0.1);
                }
              }
            }
          }
          &:last-child a {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
