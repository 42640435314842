
.slick-slide{
    &:focus{
        border: 0;
        box-shadow: none;
        outline: none;
    }
}
.testimonial-mobile-slider--l3{
    @include media-breakpoint-down(lg){
        .slick-slide {
            padding: 35px 11px 0;
        }
        .slick-dots{
            list-style: none;
            display: flex;
            justify-content: center;
            margin-top:30px ;
            li{
                
                button{
                    font-size: 0;
                    border: 0;
                    border-radius: 500px;
                    background: rgba(0,0, 0, .27);
                    margin: 0 5px;
                    width: 40px;
                    position: relative;
                    overflow: hidden;
                    height: 4px;
                    &::before{
                        content: "";
                        height: 100%;
                        width: 0%;
                        background: $primary;
                        top: 0;
                        right: 0;
                        left: auto;
                        position: absolute;
                        
                    }
                    &:focus{
                         border: 0;
                        box-shadow: none;
                        outline: none;
                    }
                }
                &.slick-active{
                    button{
                         &::before{
                             left: 0;
                             right: auto;
                             width: 100%;
                             transition: 3.5s linear;
                         }
                    }
                }
            }
        }
    }
}