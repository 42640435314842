.content-image-group {
    position: relative;
    z-index: 1;
}

/*
  Home 1 : Content Section One
---------------------------------*/
.content-section--l1-1 {
    padding-top: 100px;
    padding-bottom: 50px;

    @include tablet {
        padding-top: 130px;
        padding-bottom: 75px;
    }

    @include desktops {
        padding-top: 195px;
        padding-bottom: 214px;
    }
}

.content-texts {
    padding-top: 80px;

    @include tablet {
        padding-top: 0;
    }

    .content__heading {
        font-size: 35px;
        font-weight: 500;
        letter-spacing: -2px;
        line-height: 1.1;
        margin-bottom: 25px;

        @include mobile-lg {
            font-size: 45px;
        }

        @include tablet {
            font-size: 50px;
        }

        @include desktops {
            font-size: 60px;
            margin-bottom: 35px;
        }
    }

    .content__description {
        font-size: 18px;
        line-height: 1.66666;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.content-texts--l1-1 {
    padding-top: 90px;

    @include desktops {
        padding-left: 50px;
        padding-top: 0px;
        margin-left: 10px;
    }

    @include large-desktops {
        padding-left: 0px;
    }
}

.content-image-group--l1_1 {
    @include desktops {
        padding: 0 36px;
    }

    .content-image-group__image-1 {
        position: absolute;
        top: -20px;
        left: -5px;
        width: 30%;
        z-index: -1;

        @include mobile {
            top: -5%;
            left: -6%;
        }

        @include desktops {
            left: 0;
        }
    }

    .content-image-group__image-2 {
        position: absolute;
        top: -11%;
        right: 20%;
        z-index: -1;
        width: 35%;

        @include large-desktops {
            right: 10%;
        }
    }

    .content-image-group__image-3 {
        position: absolute;
        bottom: 11%;
        left: -3%;
        z-index: -1;
        width: 35%;

        @include mobile {
            left: -11%;
        }

        @include desktops {
            left: -5%;
        }
    }

    .content-image-group__image-4 {
        position: absolute;
        bottom: -15%;
        right: -18%;
        transform: scale(0.6);

        @include mobile {
            right: -80px;
        }

        @include tablet {
            transform: scale(0.9);
            right: -40px;
        }

        @include desktops {
            right: -40px;
            bottom: -80px;
        }

        @include large-desktops {
            transform: scale(1);
        }
    }
}

/*
  Home 1 : Content Section Two
---------------------------------*/

.content-section--l1-2 {
    padding-top: 80px;
    padding-bottom: 50px;

    @include tablet {
        padding-top: 112px;
        padding-bottom: 75px;
    }

    @include desktops {
        padding-top: 165px;
        padding-bottom: 135px;
    }
}

.content-texts--l1-2 {
    padding-top: 40px;

    @include desktops {
        padding-top: 0px;
    }
}

/*
  Home 2 : Content Section One
---------------------------------*/

.content-section--l2-1 {
    padding-top: 60px;
    padding-bottom: 60px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 132px;
        padding-bottom: 135px;
    }

    &__shape {
        position: absolute;
        left: -3.8%;
        top: 12%;
        z-index: 0;
        width: 9%;
    }
}

.content-image-group--l2-1 {
    position: relative;
    margin-bottom: 40px;

    @include large-desktops {
        margin-left: 45px;
        margin-bottom: 0;
    }

    &__img-1 {
        width: 38%;
        position: absolute;
        right: 13%;
        bottom: 0;
        z-index: -1;
    }
}

.content-texts--l2-1 {
    padding-top: 0px;

    &__heading {
        font-size: 35px;
        font-weight: 500;
        letter-spacing: -2px;
        line-height: 1.20689;
        margin-bottom: 32px;

        @include mobile {
            font-size: 38px;
        }

        @include tablet {
            font-size: 48px;
        }

        @include desktops {
            font-size: 58px;
        }
    }

    &__content {
        font-size: 18px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 1.66666;
        margin-bottom: 0;
    }

    &__button {
        margin-top: 22px;
    }
}

.content-section--l2-2 {
    &__wrapper {
        padding-top: 75px;
        padding-bottom: 60px;

        @include tablet {
            padding-top: 100px;
            padding-bottom: 80px;
        }

        @include desktops {
            padding-top: 165px;
            padding-bottom: 135px;
        }
    }

    &__shape-img {
        position: absolute;
        bottom: 10%;
        right: 10%;
        width: 3.4%;
        animation: rotation 8s ease 0s infinite alternate none running;
    }
}

.content-image-group--l2-2 {
    position: relative;
    margin-bottom: 40px;
    z-index: 1;

    @include large-desktops {
        margin-bottom: 0;
        margin-right: 55px;
    }

    &__img-1 {
        width: 38%;
        position: absolute;
        left: -1%;
        top: -7%;
        z-index: -1;
    }
}

/*
  Home 3 : Content Section One
---------------------------------*/
.content-section--l3-1 {
    padding-top: 60px;
    padding-bottom: 25px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 40px;
    }

    @include desktops {
        padding-top: 125px;
        padding-bottom: 120px;
    }

    .section-title {
        padding-bottom: 20px;

        @include desktops {
            padding-bottom: 65px;
        }

        &__sub-heading {
            margin-bottom: 15px;
        }
    }
}

.content-img--l3-1 {
    padding-bottom: 35px;

    @include desktops {
        padding-bottom: 0;
    }

    @include large-desktops {
        margin-left: 60px;
    }
}

/*
  Home 3 : Content Section Two
---------------------------------*/

.content-section--l3-2 {
    padding-top: 50px;
    padding-bottom: 10px;

    @include tablet {
        padding-top: 72px;
        padding-bottom: 32px;
    }

    @include desktops {
        padding-top: 115px;
        padding-bottom: 90px;
    }

    .section-title {
        padding-bottom: 20px;

        @include media-breakpoint-up(md) {
            padding-bottom: 30px;
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: 50px;
        }

        &__sub-heading {
            margin-bottom: 20px;
        }
    }
}

.widgets-content--l3 {
    .single-widget {
        &:nth-child(2) {
            @include media-breakpoint-down(lg) {
                & > div {
                    &::before {
                        opacity: 0;
                    }
                }
            }
        }

        &:last-child {
            & > div {
                &::before {
                    opacity: 0;
                }
            }
        }
    }
}

/*
  Home 4 : Content Section One
---------------------------------*/
.content-section--l4-1 {
    padding-top: 60px;
    padding-bottom: 7px;

    @include tablet {
        padding-top: 82px;
        padding-bottom: 27px;
    }

    @include desktops {
        padding-top: 123px;
        padding-bottom: 180px;
    }

    .section-title {
        margin-bottom: 45px !important;

        @include desktops {
            margin-bottom: 65px !important;
        }

        &__sub-heading {
            padding-bottom: 15px;
        }

        &__heading {
            padding-bottom: 13px;
        }
    }
}

.content-img--l4-1 {
    padding-bottom: 35px;

    @include desktops {
        padding-bottom: 0;
    }

    @include extra-large-desktops {
        margin-left: 120px;
    }
}

/*
  Home 4 : Content Section Two
---------------------------------*/

.content-section--l4-2 {
    padding-top: 53px;
    padding-bottom: 20px;

    @include tablet {
        padding-top: 72px;
        padding-bottom: 40px;
    }

    @include desktops {
        padding-top: 140px;
        padding-bottom: 123px;
    }

    .section-title {
        margin-bottom: 45px;
        @include desktops {
            margin-bottom: 60px;
        }
        @include large-desktops {
            margin-bottom: 0;
        }

        &__sub-heading {
            margin-bottom: 20px;
        }

        &__heading {
            margin-bottom: 15px;
        }

        &__description {
            margin-bottom: 33px;
        }
    }
}

/*
  Home 5 : Content Section One
---------------------------------*/

.content-section--l5-1,
.content-section--l8 {
    padding-top: 60px;
    padding-bottom: 20px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 40px;
    }

    @include desktops {
        padding-top: 70px;
        padding-bottom: 60px;
    }

    .section-title {
        &__sub-heading {
            margin-bottom: 20px;
        }

        &__heading {
            margin-bottom: 15px;
        }

        &__description {
            margin-bottom: 33px;
            @include desktops{
                font-weight: 400;
                letter-spacing: normal;
                line-height: 32px;
                font-size: 16px;
            }
            @include extra-large-desktops {
                font-size: 18px;
            }
        }
    }
    .content-img {
        @include brk-point(1400px) {
            padding: 0 55px;
        }
    }
    .content-right {
        @include mobile {
            margin-right: 70px;
        }
        @include tablet {
            margin-right: 30px;
            margin-top: 40px;
        }
        @include desktops {
            margin-top: 70px;
            margin-right: 0px;
        }
    }
}

/*
  Home 6 : Content Section One
---------------------------------*/

.content-section--l5 {
    padding-top: 60px;
    padding-bottom: 20px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 40px;
    }

    @include desktops {
        padding-top: 70px;
        padding-bottom: 60px;
    }

    .widgets-content--l6 {
        margin-top: 40px;
        @include desktops {
            margin-top: 73px;
        }
    }

    .content-img {
        @include large-desktops {
            margin-left: 125px;
        }
    }
}

/*
  Home 7 : Content Section One
---------------------------------*/

.content-section--l7 {
    padding-top: 60px;
    padding-bottom: 55px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 75px;
    }

    @include desktops {
        padding-top: 130px;
        padding-bottom: 130px;
    }
    @include large-desktops {
        padding-top: 130px;
        padding-bottom: 135px;
    }

    .section-title {
        &__sub-heading {
            margin-bottom: 20px;
            @include desktops {
                margin-bottom: 25px;
            }
        }
        &__heading {
            margin-bottom: 40px;
            @include desktops {
                margin-bottom: 60px;
            }
        }
    }
    .content-img--2 {
        padding: 30px 0;
        @include large-desktops {
            padding-top: 75px;
            padding-bottom: 80px;
        }
    }
}

.content-section--l7-2 {
    padding-top: 60px;
    padding-bottom: 55px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 130px;
        padding-bottom: 135px;
    }
    .section-title {
        &__sub-heading {
            margin-bottom: 20px;
        }
        &__heading {
            margin-bottom: 15px;
        }
        &__description {
            margin-bottom: 0px;
        }
    }
    .content-img {
        @include mobile-lg {
            right: -15%;
        }
        @include tablet{
            right: -25%;
        }
        @include large-desktops {
            right: -20%;
        }
    }
}

/*
  Home 8 : Content Section One
---------------------------------*/

.content-section--8 {
    padding-top: 35px;
    padding-bottom: 5px;

    @include tablet {
        padding-top: 75px;
        padding-bottom: 25px;
    }

    @include desktops {
        padding-top: 120px;
        padding-bottom: 0px;
    }
    .widgets--about-l6 {
        .widgets__icon {
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
            color: $bittersweet !important;
        }
        &:hover {
            .widgets__icon {
                background-color: $bittersweet !important;
                color: $white !important;
            }
        }
    }

    .content-img {
        margin-bottom: 30px;
        position: relative;
        z-index: 1;
        width: 80%;
        margin: 0 auto;
        .shape{
            position: absolute;
            top: 50%;
            left: 56%;
            z-index: -1;
            transform: translate(-50%, -50%);
            width: 90%;
            img{
                width: 100%;
            }
        }
    }
    .section-title {
        margin-bottom: 30px;
        @include large-desktops {
            margin-bottom: 0;
        }
        &__sub-heading {
            margin-bottom: 18px;
        }
        &__heading {
            margin-bottom: 0;
        }
    }
}

.content-section--l8-2 {
    padding-top: 35px;
    padding-bottom: 25px;

    @include tablet {
        padding-top: 75px;
        padding-bottom: 45px;
    }

    @include desktops {
        padding-top: 80px;
        padding-bottom: 0px;
    }

    .widgets-content--l8 {
        margin-top: 30px;
        @include desktops {
            margin-top: 68px;
        }
    }
    .content-img {
        position: relative;
        z-index: 1;
        width: 80%;
        margin: 0 auto;
        .shape{
            position: absolute;
            top: 50%;
            left: 56%;
            z-index: -1;
            transform: translate(-50%, -50%);
            width: 90%;
            img{
                width: 100%;
            }
        }
        @include desktops {
            margin-left: 30px;
        }
    }

    .widget--content-l6__circle {
        box-shadow: 0 4px 4px #057d7b;
    }
}

/*
  Home 9 : Content Section One
---------------------------------*/

.content-section--l9-1 {
    padding-top: 55px;
    padding-bottom: 10px;

    @include tablet {
        padding-top: 70px;
        padding-bottom: 32px;
    }

    @include desktops {
        padding-top: 65px;
        padding-bottom: 85px;
    }

    .section-title {
        margin-bottom: 40px;
        @include desktops {
            margin-bottom: 80px;
        }
        &__sub-heading {
            padding-left: 0;
            &::before {
                display: none;
            }
        }
    }
}
