/*
  Home 1 : Footer Section
------------------------------*/
.footer {
    position: relative;

    &__shape-1 {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

}


.footer-section-2 {

}