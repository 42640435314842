/*
  Home 1 : Service Section
---------------------------------*/
.service-area--l1 {
    padding-top: 55px;
    padding-bottom: 5px;
    position: relative;

    @include tablet {
        padding-top: 70px;
        padding-bottom: 25px;
    }

    @include desktops {
        padding-top: 125px;
        padding-bottom: 113px;
    }
}

.service-shape {
    position: absolute;

    &--l1 {
        top: 26%;
        left: -6%;
        width: 10%;
    }
}

.service-items {
    margin-top: 50px;

    @include desktops {
        margin-top: 143px;
    }
}

/*
  Home 3 : Service Section
---------------------------------*/
.service-area--l3 {
    padding-top: 50px;
    padding-bottom: 36px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 65px;
    }

    @include desktops {
        padding-top: 120px;
        padding-bottom: 110px;
    }

    .section-title {
        margin-bottom: 30px;
        @include desktops {
            margin-bottom: 69px;
        }

        &__sub-heading {
            margin-bottom: 27px;
        }
    }
}

.services-content {
    margin-top: 50px;
}

/*
  Home 4 : Service Section
---------------------------------*/

.service-area--l4,
.service-area--inner {
    padding-top: 50px;
    padding-bottom: 36px;

    @include tablet {
        padding-top: 70px;
        padding-bottom: 55px;
    }

    @include desktops {
        padding-top: 110px;
        padding-bottom: 100px;
    }

    .section-title {
        margin-bottom: 30px;
        @include desktops {
            margin-bottom: 55px;
        }

        &__sub-heading {
            margin-bottom: 10px;
        }
    }
}

/*
  Home 5 : Service Section
---------------------------------*/

.service-area--l5 {
    padding-top: 60px;
    padding-bottom: 36px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 55px;
    }

    @include desktops {
        padding-top: 130px;
        padding-bottom: 105px;
    }

    .section-title {
        margin-bottom: 40px;

        @include mobile {
            margin-right: 60px;
        }

        @include mobile {
            margin-right: 0px;
        }

        @include desktops {
            margin-bottom: 69px;
            margin-right: 60px;
        }

        @include large-desktops {
            margin-right: 0;
        }

        &__sub-heading {
            margin-bottom: 27px;
        }

        &__description {
            @include desktops {
                padding-right: 15px;
            }

            @include large-desktops {
                padding-right: 0px;
            }

            @include extra-large-desktops {
                padding-right: 60px;
            }
        }
    }
}

/*
  Home 6 : Service Section
---------------------------------*/

.service-area--l6 {
    padding-top: 60px;
    padding-bottom: 36px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 55px;
    }

    @include desktops {
        padding-top: 125px;
        padding-bottom: 60px;
    }

    .section-title {
        margin-bottom: 40px;

        @include mobile {
            margin-right: 60px;
        }

        @include mobile {
            margin-right: 0px;
        }

        @include desktops {
            margin-bottom: 69px;
            margin-right: 60px;
        }

        @include large-desktops {
            margin-right: 0;
        }

        &__sub-heading {
            margin-bottom: 27px;
        }

        &__description {
            @include desktops {
                padding-right: 15px;
            }

            @include large-desktops {
                padding-right: 0px;
            }

            @include extra-large-desktops {
                padding-right: 60px;
            }
        }
    }
}

/*
  Home 7 : Service Section
---------------------------------*/

.service-area--l7 {
    padding-top: 55px;
    padding-bottom: 36px;

    @include tablet {
        padding-top: 75px;
        padding-bottom: 55px;
    }

    @include desktops {
        padding-top: 130px;
        padding-bottom: 105px;
    }

    .section-title {
        margin-bottom: 40px;

        @include desktops {
            margin-bottom: 0px;
        }

        &__sub-heading {
            margin-bottom: 22px;
        }
        .btn {
            min-width: 168px;
        }
    }
    .services-right {
        @include large-desktops {
            margin-left: 35px;
        }
    }
}

/*
  Home 8 : Service Section
---------------------------------*/

.service-area--l8 {
    padding-top: 55px;
    padding-bottom: 36px;

    @include tablet {
        padding-top: 75px;
        padding-bottom: 55px;
    }

    @include desktops {
        padding-top: 110px;
        padding-bottom: 115px;
    }

    .section-title {
        margin-bottom: 40px;

        @include desktops {
            margin-bottom: 75px;
        }

        &__sub-heading {
            margin-bottom: 18px;
        }
    }
    .services-right {
        @include large-desktops {
            margin-left: 35px;
        }
    }
    .card--services-l6 {
        border-radius: 10px;
        background-color: rgba($link-water, 0.2);
    }
}

/*
  Home 9 : Service Section
---------------------------------*/

.service-area--l9 {
    padding-top: 60px;
    padding-bottom: 36px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 55px;
    }

    @include desktops {
        padding-top: 125px;
        padding-bottom: 60px;
    }

    .section-title {
        margin-bottom: 40px;

        @include mobile {
            margin-right: 60px;
        }

        @include mobile {
            margin-right: 0px;
        }

        @include desktops {
            margin-bottom: 69px;
            margin-right: 60px;
        }

        @include large-desktops {
            margin-right: 0;
        }

        &__sub-heading {
            margin-bottom: 27px;
        }

        &__description {
            @include desktops {
                padding-right: 15px;
            }

            @include large-desktops {
                padding-right: 0px;
            }

            @include extra-large-desktops {
                padding-right: 60px;
            }
        }
    }
}

.services-area--l9-1 {
    padding-top: 60px;
    padding-bottom: 30px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 50px;
    }

    @include desktops {
        padding-top: 130px;
        padding-bottom: 130px;
    }
    .section-title {
        &__description {
            &--highlight {
                padding-left: 30px;
                margin-left: 40px;
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: $electric-violet-2;
                    width: 1px;
                    height: 77px;
                }
            }
        }
    }
    .services-content-box {
        &__list {
            li {
                padding-left: 30px;
                margin-bottom: 25px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: normal;
                line-height: 28px;
                color: var(--color-headings-2);
                position: relative;
                &::before{
                    content: "\f00c";
                    font-family: 'Font Awesome 5 Free';
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-weight: 700;
                    color: $electric-violet-2;
                }
            }
        }
    }
}
