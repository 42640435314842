@keyframes rotation {
    0% {
        transform: scale(.6);
    }

    50% {
        transform: scale(.8) rotate(-45deg);
    }

    100% {
        transform: scale(1) rotate(180deg);
    }
}

@-webkit-keyframes animateMark {
  0% {
    background-size: 0% 6px;
  }

  100% {
    background-size: 100% 6px;
  }
}

@keyframes animateMark {
  0% {
    background-size: 0% 6px;
  }

  100% {
    background-size: 100% 6px;
  }
}

@keyframes sonarWave {
  from {
      opacity: 0.4;
  }

  to {
      transform: scale(2.5);
      opacity: 0;
  }
}