/* Underline animation 
-----------------------*/
mark {
  color: inherit;
  padding: 0;
  background: 0 0;
  background-image: $primary;
  background-repeat: no-repeat;
  background-size: 0 0.3em;
  background-position: 0 102%;
  position: relative;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

mark[data-aos="highlight-text"].aos-animate {
  background-size: 100% 6px;
}

.border-bottom-dotted-3 {
  border-bottom: 3px dotted rgba(37, 55, 63, 0.15);
}

/* ====== Home Page 2 ===== */
.circle {
  &-126 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    min-width: 106px;
    max-width: 106px;
    min-height: 106px;
    max-height: 106px;

    @include tablet {
      min-width: 126px;
      max-width: 126px;
      min-height: 126px;
      max-height: 126px;
    }
  }

  &-91 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    min-width: 91px;
    max-width: 91px;
    min-height: 91px;
    max-height: 91px;
  }

  &-103 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    min-width: 90px;
    max-width: 90px;
    min-height: 90px;
    max-height: 90px;

    @include tablet {
      min-width: 103px;
      max-width: 103px;
      min-height: 103px;
      max-height: 103px;
    }
  }

  &-98 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    min-width: 98px;
    max-width: 98px;
    min-height: 98px;
    max-height: 98px;
  }

  &-99 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    min-width: 99px;
    max-width: 99px;
    min-height: 99px;
    max-height: 99px;

    @include tablet {
      min-width: 99px;
      max-width: 99px;
      min-height: 99px;
      max-height: 99px;
    }
  }

  &-95 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    min-width: 85px;
    max-width: 85px;
    min-height: 85px;
    max-height: 85px;

    @include tablet {
      min-width: 95px;
      max-width: 95px;
      min-height: 95px;
      max-height: 95px;
    }
  }

  &-113 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    min-width: 95px;
    max-width: 95px;
    min-height: 95px;
    max-height: 95px;

    @include tablet {
      min-width: 113px;
      max-width: 113px;
      min-height: 113px;
      max-height: 113px;
    }
  }

  &-88 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    min-width: 78px;
    max-width: 78px;
    min-height: 78px;
    max-height: 78px;

    @include tablet {
      min-width: 88px;
      max-width: 88px;
      min-height: 88px;
      max-height: 88px;
    }
  }

  &-108 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    min-width: 98px;
    max-width: 98px;
    min-height: 98px;
    max-height: 98px;

    @include tablet {
      min-width: 108px;
      max-width: 108px;
      min-height: 108px;
      max-height: 108px;
    }
  }
}

/* Animations Sonar */
.sonar-emitter {
  position: relative;
}

.sonar-emitter::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  background-color: $primary;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}

.sonar-emitter::after {
  animation: sonarWave 2s linear infinite;
}

.sonar-emitter-2 {
  position: relative;
}

.sonar-emitter-2::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  background-color: $white;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}

.sonar-emitter-2::after {
  animation: sonarWave 2s linear infinite;
}

.menu-social-share {
  padding: 0;
  margin: 0;
  li {
    display: inline-flex;
    align-items: center;
    a {
      display: inline-flex;
      padding: 0 10px;
      color: var(--color-headings-2);
      transition: 0.4s;
      @include extra-large-desktops{
        padding: 0 20px;
      }
      &:hover {
        color: $electric-violet-2;
      }
    }
  }
}


.menu-sep-left {
  position: relative;
  @include desktops {
    margin-left: 20px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 26px;
      border-left: 2px solid var(--border-color-5);
    }
  }
}
.menu-sep-right {
  position: relative;
  @include desktops {
    margin-right: 20px;
    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 26px;
      border-right: 2px solid var(--border-color-6);
    }
  }
}

.reveal-header {
  .menu-sep-left {
    &::before {
      border-left: 2px solid rgba($white, 0.3);
    }
  }
}
