.career-details {
    padding-top: 55px;
    padding-bottom: 60px;
    @include tablet {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @include desktops {
        padding-top: 120px;
        padding-bottom: 120px;
    }
}

.career-deatils__content {
    @include desktops {
        margin-right: 65px;
    }
    .career-details-box {
        padding-top: 10px;
        .btn {
            width: 243px;
        }
        @include desktops {
            padding-top: 45px;
        }
    }
    &__heading {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 40px;
        color: var(--color-headings-2);
        margin-bottom: 20px;
    }
    &__text {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 32px;
        color: var(--color-texts-3);
    }
    &__list {
        padding: 0;
        margin: 0;
        li {
            font-size: 18px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 32px;
            list-style: none;
            padding-left: 25px;
            position: relative;
            margin-bottom: 15px;
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 12px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                display: inline-block;
                background-color: $electric-violet-2;
            }
        }
    }
}

.pormo--inner-career {
    box-shadow: 0 44px 74px rgba(37, 55, 63, 0.1);
    background-color: #e4f2f0;
    padding-top: 65px;
    padding-bottom: 65px;

    .newsletter--inner-career {
        margin: 0 13px;
    }
    .promo-content {
        .section-title {
            &__heading {
                font-size: 28px;
                font-weight: 500;
                letter-spacing: normal;
                line-height: 1.5;
                margin-bottom: 35px;
                color: $shark;
                @include tablet{
                    font-size: 30px;
                }
                @include desktops{
                    font-size: 36px;
                }
            }
            &__description {
                font-size: 16px;
                font-weight: 400;
                letter-spacing: normal;
                line-height: 1.777777;
                margin-top: 15px;
                color: rgba($shark, 0.7);
                @include desktops{
                    font-size: 18px;
                }
            }
        }
    }
}


.video-section--career{
    padding-top: 60px;
    padding-bottom: 10px;
    @include tablet{
        padding-top: 80px;
        padding-bottom: 30px;
    }
    @include desktops{
        padding-top: 120px;
        padding-bottom: 120px;
    }
}

.service-area--inner {
    padding-top: 55px;
    padding-bottom: 55px;
    @include tablet{
        padding-top: 75px;
        padding-bottom: 80px;
    }
    @include desktops{
        padding-top: 120px;
        padding-bottom: 125px;
    }
}