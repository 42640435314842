.coming-soon {
    padding-top: 60px;
    padding-bottom: 60px;
    @include tablet{
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .section-title {
        @include desktops{
            margin-bottom: 90px !important;
        }
        &__heading{
            margin-bottom: 33px;
        }
        &__description {
            font-size: 18px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 32px;
        }
    }
    .countdown-bar{
        @include desktops{
            margin-bottom: 80px;
        }
    }
}
.countdown-bar {
    ul {
        @include desktops {
            margin-right: -30px;
            margin-left: 30px;
        }
        li {
            text-align: center;
            width: 50%;
            flex: 0 0 50%;
            margin-bottom: 20px;
            @include mobile-lg {
                width: 25%;
                flex: 0 0 25%;
                margin-bottom: 0;
            }
            h2 {
                font-size: 45px;
                font-weight: 500;
                letter-spacing: normal;
                line-height: 54px;
                color: $primary;
                position: relative;
                &:before {
                    content: ":";
                    font-size: 45px;
                    font-weight: 500;
                    letter-spacing: normal;
                    line-height: 54px;
                    color: $primary;
                    right: 0;
                    top: 0;
                    position: absolute;
                }
            }
            p {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: normal;
                line-height: 28px;
                color: var(--color-texts-3);
                margin-bottom: 0;
            }
            &:nth-child(2) {
                h2:before {
                    content: ":";
                    font-size: 45px;
                    font-weight: 500;
                    letter-spacing: normal;
                    line-height: 54px;
                    color: transparent;
                    right: 0;
                    top: 0;
                    position: absolute;
                }
                @include mobile-lg {
                    h2:before {
                        content: ":";
                        font-size: 45px;
                        font-weight: 500;
                        letter-spacing: normal;
                        line-height: 54px;
                        color: $primary;
                        right: 0;
                        top: 0;
                        position: absolute;
                    }
                }
            }
            &:last-child {
                h2:before {
                    content: ":";
                    font-size: 45px;
                    font-weight: 500;
                    letter-spacing: normal;
                    line-height: 54px;
                    color: transparent;
                    right: 0;
                    top: 0;
                    position: absolute;
                    display: none;
                }
            }
        }
    }
}

.counterup__newsletter {
    margin-top: 10px;
    @include desktops {
        margin-left: 100px;
    }
    @include large-desktops {
        margin-left: 157px;
    }
    &__offer {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 32px;
    }
}
