/*Preloader css*/

div#loading {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
}
div#loading img {
  z-index: 9999;
  width: 7%;
}
.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #ffffff !important;
}
.preloader img {
  width: 500px;
}

/*Header Css StaRT*/

.site-navbar {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  @include desktops {
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding-top: 0px;
  }

  .menu-block-wrapper {
    flex-grow: 1;
    align-items: center;
    @include desktops {
      display: flex !important;
      flex-basis: auto;
    }
  }
}

.site-header {
  &--absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }

  &--sticky {
    &:not(.mobile-sticky-enable) {
      position: absolute !important;
      top: 0;
      right: 0;
      width: 100%;
      z-index: 999;

      @include desktops {
        position: fixed !important;
        transition: 0.4s;

        &.scrolling {
          transform: translateY(-100%);
          transition: 0.4s;
        }

        &.reveal-header {
          transform: translateY(0%);
          box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
          z-index: 1000;
          a {
            color: $shark;
          }
          .btn-link{
            color: var(--color-headings-2);
          }
          // .menu-social-share {
          //   li{
          //     a{
          //       color: $white;
          //     }
          //   }
          // }
        }
      }
    }

    &.mobile-sticky-enable {
      top: 0;
      right: 0;
      width: 100%;
      z-index: 999;
      position: fixed !important;
      transition: 0.4s;

      &.scrolling {
        transform: translateY(-100%);
        transition: 0.4s;
      }

      &.reveal-header {
        transform: translateY(0%);
        box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
        z-index: 9999;
      }
    }
  }

  &--menu {
    &-center {
      .container {
        position: relative;
      }
      .menu-block-wrapper {
        position: static;
      }
      .menu-block {
        @include desktops {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &-left {
      .container-fluid {
        .sub-menu.megamenu {
          @include desktops {
            left: 50%;
            transform: translateX(-50%) translateY(10px);
          }
        }

        .nav-item.has-megamenu:hover > .sub-menu {
          @include desktops {
            transform: translateX(-50%) translateY(-2px);
            left: 50%;
          }
        }
      }

      .menu-block {
        .site-menu-main {
          justify-content: flex-start;
          padding-left: 15px;
        }

        @include large-desktops {
          width: 100%;
        }
      }
    }

    &-right {
      .menu-block {
        margin-left: auto;
      }

      > .container-fluid {
        .sub-menu.megamenu {
          @include desktops {
            left: 100%;
            transform: translateX(-100%) translateY(10px);
          }
        }

        .nav-item.has-megamenu:hover > .sub-menu {
          @include desktops {
            transform: translateX(-100%) translateY(-10px);
            left: 100%;
          }
        }
      }
    }
  }
}

// .container-fluid{
//   .site-navbar{
//     @include tablet {
//       padding-left: 10px;
//       padding-right: 10px;
//     }
//     @include desktops {
//       padding-left: 30px;
//       padding-right: 30px;
//     }
//     @include large-desktops{
//       padding-left: 60px;
//       padding-right: 60px;
//     }
//   }
// }

.header-btns {
  @include mobile {
    margin-right: 15px;
  }
  @include desktops {
    margin-right: 0;
  }
}

.brand-logo {
  // margin-top: 7px;
}

.dynamic-sticky-bg {
  &.reveal-header {
    background: var(--bg);
  }
  &.dark-mode-texts{
    .menu-block.active .nav-link-item{
      color: $shark !important;
    }
    .menu-social-share
     li{
       a{
         color: var(--color-headings-2);
       }
     }
  }
}

.sticky-bg-white {
  &.reveal-header {
    background: #fff;
    .dark-version-logo {
      display: none !important;
    }
    .light-version-logo {
      display: block !important;
    }

    .menu-sep-left {
      position: relative;
      &::before {
        content: "";
        border-left: rgba($shark, 0.5) !important;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 26px;
        z-index: 9;
      }
    }
  }
}

.notification-block {
  position: relative;
  .count {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -4px;
    margin-right: -5px;
  }
}


body[data-theme="dark"],
.dark-mode-texts {
  .reveal-header {
    .menu-social-share {
      li {
        a {
          color: $white;
          transition: 0.4s;
          &:hover {
            color: $electric-violet-2;
          }
        }
      }
    }
    .nav-link-item{
      color: $shark !important;
      @include desktops{
        color: var(--color-headings-2) !important;
      }
    }

  }
}