.video-section{
    .section-title{
        margin-right: 30px;
        &__heading{
            margin-bottom: 25px !important;
        }
        &__description{
            margin-bottom: 25px;
        }
        
    }
}
.video-box{
    @include large-desktops{
        margin-left: 110px;
    }
}
