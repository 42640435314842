/*
  Home 4 : Pricing Table
---------------------------------*/
.table-section--l4 {
    padding-top: 60px;
    padding-bottom: 40px;

    @include tablet {
        padding-top: 70px;
        padding-bottom: 55px;
    }

    @include desktops {
        padding-top: 120px;
        padding-bottom: 100px;
    }

    .section-title {
        margin-bottom: 45px;

        @include desktops {
            margin-bottom: 75px;
        }
    }
}

/*
  Home 5 : Pricing Table
---------------------------------*/
.table-section--l5,
.table-section--inner-2 {
    padding-top: 60px;
    padding-bottom: 40px;

    @include tablet {
        padding-top: 70px;
        padding-bottom: 55px;
    }

    @include desktops {
        padding-top: 120px;
        padding-bottom: 100px;
    }

    .table-section-top {
        margin-bottom: 45px;

        @include desktops {
            margin-bottom: 70px;
        }
    }

    .section-title {
        margin-bottom: 25px;

        @include desktops {
            margin-bottom: 0px;
        }

        &__sub-heading {
            margin-bottom: 25px;
        }

        &__heading {
            font-size: 32px;
            font-weight: 500;
            letter-spacing: normal;
            line-height: 1.2;

            @include tablet {
                font-size: 35px;
            }

            @include desktops {
                font-size: 45px;
            }
        }
    }
}

/*
  Home 5 : Pricing Table
---------------------------------*/
.table-section--l6 {
    padding-top: 60px;
    padding-bottom: 40px;

    @include tablet {
        padding-top: 70px;
        padding-bottom: 55px;
    }

    @include desktops {
        padding-top: 120px;
        padding-bottom: 100px;
    }

    .table-section-top {
        margin-bottom: 45px;

        @include desktops {
            margin-bottom: 75px;
        }
    }

    .section-title {
        margin-bottom: 45px;

        @include desktops {
            margin-bottom: 0;
        }
    }
}

/*
  Home 8 : Pricing Table
---------------------------------*/
.table-section--l8 {
    padding-top: 60px;
    padding-bottom: 40px;

    @include tablet {
        padding-top: 70px;
        padding-bottom: 55px;
    }

    @include desktops {
        padding-top: 120px;
        padding-bottom: 100px;
    }

    .table-section-top {
        margin-bottom: 45px;

        @include desktops {
            margin-bottom: 75px;
        }
    }

    .section-title {
        margin-bottom: 25px;

        @include desktops {
            margin-bottom: 0px;
        }

        &__sub-heading {
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            line-height: normal;
        }
    }

    .pricing-btn {
        .btn-toggle {
            background-color: $white !important;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");

            &:focus {
                border: none;
                outline: none;
                box-shadow: none;
                background-color: $white !important;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
            }

            &:checked {

                background-color: $bittersweet !important;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
            }
        }
    }
}

/*
  Home 8 : Pricing Table
---------------------------------*/
.table-section--inner-2 {
    @include desktops {
        padding-top: 135px;
        padding-bottom: 115px;
    }
}