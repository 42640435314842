/*
  Home 3
---------------------------------*/
.pormo--l3 {
    position: relative;
    z-index: 1;
    padding-top: 50px;
    padding-bottom: 60px;
    background-position: center;
    background-size: cover;

    @include tablet {
        padding-top: 70px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 120px;
        padding-bottom: 130px;
    }

    &::before {
        content: "";
        background-color: rgba($electric-violet-2, 0.9);
        mix-blend-mode: multiply;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
    }

    &__heading {
        font-size: 45px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 1.2;
        margin-bottom: 20px;
    }

    &__text {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 1.75;
        color: var(--color-headings-2);
    }
}

/*
  Home 4
---------------------------------*/

.promo-area--l4 {
    padding-top: 60px;
    padding-bottom: 60px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

.pormo--l4 {
    position: relative;
    z-index: 1;
    padding-top: 50px;
    padding-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
    background-position: center;
    background-size: cover;
    border-radius: 25px;

    @include tablet {
        padding-top: 70px;
        padding-bottom: 80px;
        padding-left: 110px;
        padding-right: 100px;
    }

    @include desktops {
        padding-top: 80px;
        padding-bottom: 60px;
    }

    &::before {
        content: "";
        background-color: rgba($silver, 1);
        mix-blend-mode: multiply;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 25px;
        z-index: -1;
    }

    .section-title {
        &__sub-heading {
            margin-bottom: 15px;
        }

        &__heading {
            color: $white;
            margin-bottom: 12px;
        }

        &__description {
            margin-bottom: 30px;
            color: $white;
        }
    }
}

/*
  Home 5
---------------------------------*/
.promo-area--l5 {
    padding-top: 60px;
    padding-bottom: 60px;
    background-repeat: no-repeat;
    background-size: cover;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

.pormo--l5-content {
    border-radius: 15px;
    background-color: rgba(80, 52, 252, 0.85);
    padding-top: 56px;
    padding-bottom: 56px;
    padding-left: 30px;
    padding-right: 30px;

    .badge--promo {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 22px;
        border-radius: 15px;
        background-color: #fcdc00;
        color: $shark;
        min-width: 112px;
        height: 30px;
        margin-bottom: 22px;
    }
    .section-title {
        &__heading {
            font-size: 36px;
            font-weight: 500;
            letter-spacing: normal;
            line-height: 1.5;
            margin-bottom: 13px;
        }
        &__description {
            color: $white;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 1.875;
            margin-bottom: 40px;
        }
    }
}

/*
  Home 6
---------------------------------*/
.promo-area--l6,
.promo-area--l8 {
    padding-top: 60px;
    padding-bottom: 60px;
    background-repeat: no-repeat;
    background-size: cover;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .promo--l6-newsletter {
        @include tablet {
            margin-right: 50px;
        }
        @include desktops {
            margin-right: 56px;
        }
        @include large-desktops {
            margin-right: 76px;
        }
    }
}
.pormo--l6-content,
.pormo--l8-content {
    padding-top: 65px;
    padding-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 25px;
    @include mobile-lg {
        padding-left: 60px;
        padding-right: 60px;
    }
    @include tablet {
        padding-left: 80px;
        padding-right: 50px;
    }

    .section-title {
        &__sub-heading {
            margin-bottom: 35px;
        }
        &__heading {
            font-size: 32px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            line-height: 1.38095;
            margin-bottom: 47px;
            @include tablet {
                font-size: 35px;
            }
            @include desktops {
                font-size: 42px;
            }
        }
    }
}

/*
  Home 7
---------------------------------*/

.promo-area--l7,
.promo-area--inner-2 {
    padding-top: 35px;
    padding-bottom: 60px;

    @include tablet {
        padding-top: 55px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 100px;
        padding-bottom: 130px;
    }
}

.pormo--l7 {
    position: relative;
    z-index: 1;
    padding-top: 50px;
    padding-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
    background-position: center;
    background-size: cover;
    border-radius: 25px;

    @include tablet {
        padding-top: 70px;
        padding-bottom: 80px;
        padding-left: 110px;
        padding-right: 100px;
    }

    @include desktops {
        padding-top: 110px;
        padding-bottom: 100px;
    }

    &::before {
        content: "";
        background: linear-gradient(261.26deg, rgba(253, 52, 110, 0.96) 14.9%, #b81c5c 43.58%, #0abfbc 89.94%);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 25px;
        z-index: -1;
    }

    .section-title {
        &__sub-heading {
            margin-bottom: 15px;
        }

        &__heading {
            color: $white;
            margin-bottom: 17px;
        }

        &__description {
            margin-bottom: 30px;
            color: $white;
        }
    }
}

/*
  Home 8
---------------------------------*/
.promo-area--l8 {
    background-image: linear-gradient(300deg, #099a97 0%, #099a97 33%, #20aa8e 53%, #20aa8e 53%, #82c4c3 100%);
    position: relative;
    .promo-image {
        @include large-desktops {
            position: absolute;
            bottom: 0;
            left: 0;
        }
        @include extra-large-desktops {
            left: 10%;
        }
    }
}
.pormo--l8-content {
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.87) 0%,
        rgba(255, 255, 255, 0.62) 29%,
        rgba(255, 255, 255, 0.2) 100%
    );

    .section-title {
        &__sub-heading {
            color: $shark;
            margin-bottom: 10px;
            @include desktops {
                margin-bottom: 30px;
            }
        }
        &__heading {
            font-size: 32px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            line-height: 1.38095;
            margin-bottom: 25px;
            color: $shark;
            @include tablet {
                font-size: 35px;
            }
            @include desktops {
                font-size: 42px;
                margin-bottom: 47px;
            }
        }
    }

    .promo--l6-newsletter {
        @include mobile {
            background-color: rgba(white, 0.4);
        }
        form {
            input {
                background-color: rgba(white, 0.4);
                @include mobile {
                    background-color: transparent !important;
                }
                color: $shark !important;
                &:placeholder {
                    background-color: transparent;
                    color: #ddd;
                }
            }
            .btn {
                box-shadow: none;
            }
        }
    }
}

/*
  promo 2 inner page
---------------------------------*/

.promo-area--inner-2 {
    padding-top: 60px;
    @include tablet {
        padding-top: 80px;
    }
    @include desktops {
        padding-top: 120px;
        padding-bottom: 120px;
    }
}
/*
  About US- Promo Area
---------------------------------*/
.promo-area--inner-about {
    padding-top: 50px;
    padding-bottom: 60px;
    position: relative;
    @include tablet {
        padding-top: 70px;
        padding-bottom: 80px;
    }
    @include desktops {
        padding-top: 100px;
        padding-bottom: 120px;
    }
    .promo-content {
        &__title {
            font-size: 32px;
            font-weight: 500;
            letter-spacing: -1px;
            line-height: 1.3;
            @include tablet {
                font-size: 35px;
            }
            @include desktops {
                font-size: 48px;
            }
        }
        .btn {
            width: 255px;
        }
    }
    .shape {
        position: absolute;
        right: 0;
        top: 0;
        width: 8%;
    }
}

/*
 Home9 - Promo Area
---------------------------------*/

.promo-area--l9 {
    padding-top: 35px;
    padding-bottom: 60px;
    @include tablet{
        padding-top: 55px;
        padding-bottom: 75px;
    }
    @include desktops{
        padding-top: 0;
        padding-bottom: 0;
    }
    .promo {
        position: relative;
        min-height: 306px;
        border-radius: 15px;
        padding: 30px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        margin-top: 25px;
        @include tablet{
        padding: 30px 70px;
        }
        @include desktops{
            top: -130px;
        }
        &-wrapper{
            width: 100%;
        }
        &--box-1{
            box-shadow: 0 4px 40px rgba(80, 52, 252, 0.3);
        }
        &--box-2{
            box-shadow: 0 4px 40px rgba(0, 0, 0, 0.2);
        }
    }
    .section-title {
        &__sub-heading {
            padding-left: 0 !important;
            color: var(--color-texts-3);
            margin-bottom: 0px;
            @include desktops{
                margin-bottom: 17px;

            }
            &::before {
                display: none;
            }
        }
        &__heading {
            font-size: 36px;
            font-weight: 500;
            letter-spacing: normal;
            line-height: 54px;
            margin-bottom: 10px;
            @include desktops{
                margin-bottom: 25px;
            }
        }
    }
}


/*
  Services Page - Promo Area
---------------------------------*/
.promo-area--inner-service {
    padding-top: 50px;
    padding-bottom: 60px;
    position: relative;
    @include tablet {
        padding-top: 70px;
        padding-bottom: 80px;
    }
    @include desktops {
        padding-top: 100px;
        padding-bottom: 120px;
    }
    .promo-content {
        &__title {
            font-size: 32px;
            font-weight: 500;
            letter-spacing: -1px;
            line-height: 1.3;
            @include tablet {
                font-size: 35px;
            }
            @include desktops {
                font-size: 48px;
            }
        }
        .btn-2 {
            min-width: 215px;
        }
    }
    .shape {
        position: absolute;
        right: 0;
        top: 0;
        width: 8%;
    }
}